import React, { Component } from 'react';
import { ManazerskaData } from './ManazerskaData';
import { ManazerskaDataMenu } from './ManazerskaDataMenu';


export class NeuspesnostSoucasti extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    fetchData(year) {
        const fetch_location = this.props.child_data["data_location"];
        fetch(fetch_location, {
            method: 'POST',
            dataType: "json",
            body: JSON.stringify({ "year": year }),
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'), // Add the authentication header
                "Accept": "application/json"
            },
        }).then(response => response.json())
            .then(data => {               
                this.setState({ "data": data });
            });;
    }   
       
    render() {
        return (
            <ManazerskaData title="Počty neúspěšných studií dle součásti, typu a formy studia" >
                <div>
                    <ManazerskaDataMenu fetchData={this.fetchData.bind(this)} tb_number={this.props.table_number} headers={this.props.header} data={this.state.data} child_data={this.props.child_data} />
                    <div className="ac-table-div-auto">
                        <table className='ap-table-auto-position col-sm-12'>
                            <thead className="ap-table-header">
                                <tr>
                                    <th rowspan="2" colspan="3" scope="colgroup">Vysoká škola (název)</th>
                                    <th rowspan="1" colspan="3" scope="colgroup">Bakalářské studium</th>
                                    <th rowspan="1" colspan="3" scope="colgroup">Magisterské studium</th>
                                    <th rowspan="1" colspan="3" scope="colgroup">Navazující magisterské studium</th>
                                    <th rowspan="1" colspan="3" scope="colgroup">Doktorské studium</th>
                                    <th rowspan="2" colspan="1" scope="colgroup">CELKEM</th>
                                </tr>
                                <tr>
                                    <th rowspan="1" colspan="1" scope="colgroup">Prezenční</th>
                                    <th rowspan="1" colspan="1" scope="colgroup" title="Kombinované/Dálkové">K/D</th>
                                    <th rowspan="1" colspan="1" scope="colgroup">CELKEM</th>
                                    <th rowspan="1" colspan="1" scope="colgroup">Prezenční</th>
                                    <th rowspan="1" colspan="1" scope="colgroup" title="Kombinované/Dálkové">K/D</th>
                                    <th rowspan="1" colspan="1" scope="colgroup">CELKEM</th>
                                    <th rowspan="1" colspan="1" scope="colgroup">Prezenční</th>
                                    <th rowspan="1" colspan="1" scope="colgroup" title="Kombinované/Dálkové">K/D</th>
                                    <th rowspan="1" colspan="1" scope="colgroup">CELKEM</th>
                                    <th rowspan="1" colspan="1" scope="colgroup">Prezenční</th>
                                    <th rowspan="1" colspan="1" scope="colgroup" title="Kombinované/Dálkové">K/D</th>
                                    <th rowspan="1" colspan="1" scope="colgroup">CELKEM</th>
                                </tr>
                            </thead>
                            <tbody className="pr-tbody" id="ap-uk-tab">
                                {
                                    this.state.data.map((row) => (
                                        <tr className="pr_tr ed-uk-tr">
                                            <td colspan="3" className="pr_td">{row[0]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[1]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[2]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[3]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[4]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[5]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[6]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[7]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[8]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[9]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[10]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[11]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[12]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[13]}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </ManazerskaData>
            );
    }
}
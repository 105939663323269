import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/login/Logo SU.png';
import '../css/logout.css';
import titul from '../images/login/titul_mensi_login.png';

export default class Logout extends Component {
    constructor(props) {
        super(props)

        this.state = { year: new Date() }

        document.body.classList.add('log-background-set');
    }
    render() {
        localStorage.removeItem('token');
        return (
            <div className="container">
                <div className="login-head">
                    <img src={logo} className="login-head-img" />
                </div> 
                <div className="logout-form">
                    <img src={titul} alt="" className="login-img login-app-title" />
                    <div className="login-div login-header-div">
                        <h2 className="login-app-header">Byl jste odhlášen!</h2>
                    </div>
                    <div className="login-div">
                        <Link to="/" className="logout-link">Znova přihlásit</Link>
                    </div>
                </div>
                <div className="login-footer">
                    <div className="login-footer-sub-div"> Copyright © {this.state.year.getFullYear() + " "}
                        <a className="footer-link" href="https://www.slu.cz/slu/cz/citonas">Centrum informačních technologií­</a></div>
                </div>
            </div>
        )
    }
}


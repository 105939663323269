import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/navMenu.css';
import logo from '../images/SU-logo-CZ-new-CMYK_horizontal-inverzni.png';
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';

export class NavMenu extends Component {
    constructor(props) {
        super(props);
        this.state = { cro: "" };

        this.user_menu_click = this.user_menu_click.bind(this);
    }
    displayName = NavMenu.name

    componentWillMount(){
        fetch('api/Login/GetCRO', {
            method: 'POST',
            dataType: "json",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'), // Add the authentication header
                "Accept": "application/json"
            },
        }).then(response => response.json())
            .then(data => {
                if (data === "") {  
                    this.setState({ redirect: true });
                }
                this.setState({ cro: data });
            });
    }

    user_menu_click() {
        this.setState({ redirect: true });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to={'/logout'} />;
        }
        return (
            <div>
                <Navbar className="horNav" fixed="top">
                    <span className="col-md-2 col-xs-6">
                        <a className=" align-middle" href="/home">
                            <img src={logo}  className=" logo" alt="Slezská univerzita v Opavě" />
                        </a>
                    </span>
                    <div className="nav-logout-div">                           
                        <span className="nav-el"><Link to="/napoveda">NÁPOVĚDA</Link></span>
                        <span className="nav-svg-span nav-el-svg"> 
                            <svg height="60" width="40" className="nav-svg-position">
                                <line x1="30" x2="20" y1="20" y2="40" stroke="white" strokeWidth="3" />
                                <line x1="38" x2="28" y1="20" y2="40" stroke="white" strokeWidth="3" />
                            </svg>
                        </span>
                        <span>{this.state.cro}</span>
                        <span className="nav-svg-span"> 
                            <svg height="60" width="40" className="nav-svg-position">
                                <line x1="30" x2="20" y1="20" y2="40" stroke="white" strokeWidth="3" />
                                <line x1="38" x2="28" y1="20" y2="40" stroke="white" strokeWidth="3" />
                            </svg>
                        </span>
                        <button className="nav-pan-main-btn" onClick={this.user_menu_click} >ODHLÁSIT</button>
                    </div>
                </Navbar>
            </div>
        );
    }
}

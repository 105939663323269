import React, { Component } from 'react';
import '../../css/prijimaciRizeni.css';
import DatePicker from 'react-date-picker';
import { ManazerskaData } from '../ManazerskaData/ManazerskaData';
import { headers_config } from '../ManazerskaData/ManazerskaDataConfig'
import { ExportMessage } from '../ExportMessage'
import { confirmAlert } from 'react-confirm-alert';
import { return_file } from '../ExportData'


export class PrijimaciRizeni extends Component {
    constructor(props) {
        document.body.classList.add('mainComp');
        super(props);
        this.state = {
            fakulta_list: [],
            fakulta: "", data: [], filtered_data: [], startDate: new Date(),
            txt_el: ['prg-inp', 'obr-inp', 'vic-inp'], cat_el: ['kolo-drop', 'typ-drop', 'form-drop'], num_el: ['zapsani-drop', 'prijati-drop', 'neprijati-drop', 'nedostavili-drop', 'celkem-drop'],
        };   //data obsahuji vsechny data, filtered_data obsahuje pouze vyfiltrovana data

        this.roll_down_menu = this.roll_down_menu.bind(this);
        this.roll_back_menu = this.roll_back_menu.bind(this);
        this.sort = this.sort.bind(this);
        this.filterText = this.filterText.bind(this);
        this.filterAllColumns = this.filterAllColumns.bind(this);
        this.changeArea = this.changeArea.bind(this);
    }

    componentDidMount() {
        this.fetchFaculties();
        this.fetchData();
    }

    fetchFaculties() {
        //Nacte fakulty a ulozi je do state fakulta_list
        fetch('api/PrijimaciRizeni/FetchFaculties', {
            method: 'POST',
            dataType: "json",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Accept": "application/json"
            },
        }).then(response => response.json())
            .then(data => {
                for (var faculty in data) {
                    this.setState({ fakulta_list: [...this.state.fakulta_list, faculty] })
                }
            });;
    }

    fetchData() {
        //Nacte data a prida polozky do drop menu
        var post_data = { "faculty": this.state.fakulta.toString(), "year": this.state.startDate.getFullYear() };
        fetch(this.props.child_data["data_location"], {
            method: 'POST',
            dataType: "json",
            body: JSON.stringify(post_data),
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Accept": "application/json"
            },
        }).then(response => response.json())
            .then(data => {
                this.setState({ "data": data, "filtered_data": data }) 
                //Vytvorim polozky pro drop menu
                for (var i = 0; i < this.state.cat_el.length; i++) {
                    var column_number = i + 1;
                    this.add_list_to_drop(data, column_number, this.state.cat_el[i]);
                }

                for (var i = 0; i < this.state.num_el.length; i++) {
                    this.renderNumericOptions(data, this.state.num_el[i], i + 6)    
                }
            });;
    }

    roll_down_menu(data, menu_id) {
        //Vytvori div s listem a prida ho do parrent div
        var drop_menu = document.getElementById(menu_id);
        var div = document.createElement("div");
        div.classList = "pr-drop-div";
        div.id = menu_id + "_sub_div";
        var ul = document.createElement("ul");
        for (var i = 0; i < data.length; i++) {
            var li = document.createElement("li");
            li.innerText = data[i];
            li.id = data[i];
            li.addEventListener("click", (event) => this.changeArea(event, menu_id));
            ul.appendChild(li)
        }
        div.append(ul)
        drop_menu.appendChild(div);
    }

    roll_back_menu(element) {
        //Smaze child div z rolldown menu
        var drop_menu = document.getElementById(element);
        if (drop_menu !== null) {
            drop_menu.remove();
        }
    }

    changeArea(event, drop_id) {
        //Nastavi state.data podle vybrane soucasti
        //Nastavim novy text v drop_menu
        var drop_menu = document.getElementById(drop_id);
        drop_menu.innerText = event.target.innerText;
        var option = event.target.id;
        this.setState({ "fakulta": option }, () => { this.fetchData() });
    }

    add_list_to_drop(data, column, menu_id) {
        //Najde vsechny unique polozky a prida je do drop menu 
        data = data.map(function (value) { return value[column]; })
        var uniqueData = Array.from(new Set(data))
        this.create_options(menu_id, uniqueData)
    }

    on_drop_select(menu_id, event) {
        //Nastavi novou hodnotu a zavola trizeni
        var new_state;
        if (event.target.value !== -1) {
            new_state = event.target.value;
        }
        else {
            new_state = event.target.innerText;
        }
        this.setState({ [menu_id]: new_state }, () => { this.filterAllColumns() });
    }

    renderNumericOptions(data, menu_id, column) {                    //Z dat urci ciselne intervaly mezi max a min hodnotou po 30
        data = data.map(function (value) { return value[column]; })
        var max = parseInt(Math.max(...data) / 30);
        var min = parseInt(Math.min(...data) / 30);
        var values = [];
        var keys = [];
        for (var i = max; i >= min; i--) {
            values.push(i * 30);
            keys.push("> " + i * 30);
        }
        this.create_options(menu_id, keys, values);                         
    }

    create_options(menu_id, keys, values) {
        // Prida do drop menu list
        var div = document.createElement("div");
        div.style.display = "none";
        div.className = "pr-drop-list";
        var ul = document.createElement("ul");
        for (var i = 0; i < keys.length; i++) {
            var li = document.createElement("li")
            li.innerText = keys[i];
            if (values) {
                li.value = values[i];
            }
            else {
                li.value = -1;
            }
            li.addEventListener("click", (event) => this.on_drop_select(menu_id, event));
            ul.appendChild(li);
        }
        div.appendChild(ul);
        var drop_menu = document.getElementById(menu_id);
        if (drop_menu !== null) {
            drop_menu.appendChild(div);
        }
    }

    select_drop_item(event, state_name, drom_menu_div) { 
        //Pokud je polozka v state pak ji odstrani a zavola pro nova data pokud ne pak prida do state a zavola data
        var state = this.state[state_name];
        var id = event.target.id;
        var drop_div = document.getElementById(drom_menu_div);
        drop_div.innerHTML = "";

        if (state.includes(id)) {
            const index = state.indexOf(id);
            if (index > -1) {
                state.splice(index, 1);
            }
        }
        else {
            state.push(id);
        }
        this.setState({ [state_name]: state }, () => {
            this.fetchData();
            if (state.length === 0) {                   //Pokud je state prazdny pak prida do prazdny paragraph do div
                var p = document.createElement("p");
                var glyph = document.createElement("i");
                glyph.classList = "glyphicon glyphicon-chevron-right pr-drop-glyph";
                p.appendChild(glyph);
                p.classList = "pr-paragraph";
                drop_div.appendChild(p);
                return;
            }
            for (var i = 0; i < state.length; i++) {
                drop_div.innerHTML += state[i] + " ";
            }
        });           
    }
      
    sort(column) {              //Setridi data od nejvyssiho po nejmensi podle soupce column
        if (typeof this.state.data === 'undefined' || this.state.data.length === 0) {
            return;
        }
        var data = this.state.filtered_data;
        for (var i = 0; i < data.length; i++) {
            for (var j = 0; j < data.length; j++) {
                if (parseInt(data[j][column], 10) < parseInt(data[i][column], 10)) {
                    for (var k = 0; k < data[j].length; k++) {
                        var res = data[i][k];
                        data[i][k] = data[j][k];
                        data[j][k] = res;
                    }
                }
            }
        }
        this.setState({ "data": data }, () => { this.renderTable(data) });
    }
    
    filterText(text, data, column) {                      //Vyberu pouze zaznamy obsahujici retezec text
        var filtered_data = [];
        for (var i = 0; i < data.length; i++) {
            if (data[i][column].toLowerCase().includes(text.toLowerCase())) {
                filtered_data.push(data[i]);
            }
        }
        return filtered_data;
    }

    filterCategory(option, data, column) {
        var filtered_data = [];                         //Filtruje zaznamy podle vybraneho kola studia, typu studia a formy studia
        for (var i = 0; i < data.length; i++) {
            if (data[i][column].toLowerCase().includes(option.toLowerCase())) {
                filtered_data.push(data[i]);
            }
        }
        return filtered_data;
    }

    filterNumCategory(option, data, column) {
        var int_option = parseInt(option);
        var filtered_data = [];
        for (var i = 0; i < data.length; i++) {
            if (parseInt(data[i][column]) >= int_option) {
                filtered_data.push(data[i]);
            }
        }
        return filtered_data;
    }
        
    filterAllColumns() {
        //Vyfiltruje data podle zadaneho textu, vybrane kategorie a vybraneho intervalu
        var txt_cols = [0, 4, 5];
        var cat_cols = [1, 2, 3];
        var num_cols = [6, 7, 8, 9, 10];

        var filtered_data = this.state.data;
        for (var i = 0; i < this.state.txt_el.length; i++) {
            var input = document.getElementById(this.state.txt_el[i]);
            filtered_data = this.filterText(input.value, filtered_data, txt_cols[i]);               
        }
        for (var i = 0; i < this.state.cat_el.length; i++) {
            var drop_id = this.state.cat_el[i];
            var selected_option = this.state[drop_id];
            if (!selected_option) 
                continue;
            filtered_data = this.filterCategory(selected_option.toString(), filtered_data, cat_cols[i]);
        }
        for (var i = 0; i < this.state.num_el.length; i++) {
            var drop_id = this.state.num_el[i];
            var selected_option = this.state[drop_id];
            if (!selected_option)
                continue;
            filtered_data = this.filterNumCategory(selected_option, filtered_data, num_cols[i]);
        }

        this.setState({ "filtered_data": filtered_data });
    }

    submit = () => {                                        //Vytvori okno pro vyber mezi exportem pdf excel
        confirmAlert({
            customUI: ({ onClose }) => {
                var child_data = this.props.child_data;
                var headers = headers_config["prijimaci_rizeni"];
                var data = this.state.filtered_data;

                function confirmCallback(choice) {
                    if (choice == 1) {                      //Byl vybran export do excellu
                        return_file(new Date(), child_data, data, headers, "api/ExcellExport/GenerateExcelFile", false);
                    }
                    else if (choice == 2) {                  //Byl vybran export do pdf                                                 
                        return_file(new Date(), child_data, data, headers, "api/PDFExport/GeneratePDFFile", false);
                    }
                    onClose()                               //Zavru confirm window
                }
                return (
                    <ExportMessage confirmCallback={confirmCallback} />
                )
            }
        });
    };

    render_drop_menu(menu_id) {
        //Nastavi drom menu visible block
        var el = document.getElementById(menu_id);
        el.lastChild.style.display = "block";
    }

    clear_rendered_menu(menu_id) {
        //Nastavi drop menu none 
        var el = document.getElementById(menu_id);
        if (el.lastChild.tagName === 'DIV') {
            el.lastChild.style.display = "none";
        }
    }

    onChangeStartDate = startDate => this.setState({ startDate }, () => { this.fetchData() })

    render() {
        return (
            <ManazerskaData title="Souhrn výsledků přijímacího řízení">
                <div>
                    <div className="row pr-head-div">
                        <div className="col-md-2 pr-head-sel-margin us-sel-list">
                            <label className="pr-label">FAKULTA</label>
                            <div className="pr-list arrow" id="listFak" onMouseEnter={() => this.roll_down_menu(this.state.fakulta_list, 'listFak')} onMouseLeave={() => this.roll_back_menu('listFak_sub_div')}><p className="pr-paragraph">SU<i className="glyphicon glyphicon-chevron-right pr-drop-glyph"></i></p></div>
                        </div>
                        <div className="col-md-2 pr-head-sel-margin">
                            <label htmlFor="yearStart" title="Rok přijímacího řízení." className="pr-label us-sel-list">ROK</label>
                                <DatePicker className="pr-date-picker"
                                    id="yearStart"
                                    format='yy'
                                    minDate={new Date(1991, 0)}
                                    maxDate={new Date()}
                                maxDetail="decade"
                                onChange={this.onChangeStartDate}
                                    value={this.state.startDate}
                                />
                        </div>
                        <div className="col-md-2 col-xs-12">
                            <input type="button" className="pr-exp-btn" value="EXPORTOVAT" onClick={this.submit} />
                        </div>
                    </div>
                    <div className="ac-table-div-auto us-tab-wdith"> 
                        <table className='ap-table-auto-position col-sm-12'>
                            <div className="ap-head-div-sh">
                                <thead className="ap-table-header-scrollbar">
                                    <tr className="text-left pr_tr">
                                        <th colspan="2" className="pr_th-right-space" title="Studijní program">
                                            <input className="pr-inp glyphicon" id="prg-inp" onChange={(event) => { this.filterAllColumns(); }} type="text" placeholder="PROGRAM &#xe003;"></input>
                                        </th>
                                        <th colspan="1" className="pr_th-right-space" title="Kolo příjímacího řízení.">
                                            <div id="kolo-drop" className="pr-table-drop-down" onMouseEnter={() => this.render_drop_menu('kolo-drop')} onMouseLeave={() => this.clear_rendered_menu('kolo-drop')}>
                                                <p className="pr-drop-par">KOLO</p>
                                            </div>
                                        </th>
                                        <th colspan="1" className="pr_th-right-space" title="Tup studia: B bakalářské, N navazující magisterské studium, D doktorské studium">
                                            <div id="typ-drop" className="pr-table-drop-down" onMouseEnter={() => this.render_drop_menu('typ-drop')} onMouseLeave={() => this.clear_rendered_menu('typ-drop')}>
                                                <p className="pr-drop-par">TYP</p>
                                            </div>
                                        </th>
                                        <th colspan="1" className="pr_th-right-space" title="Froma studia: P prezenční, K kombinované">
                                            <div id="form-drop" className="pr-table-drop-down" onMouseEnter={() => this.render_drop_menu('form-drop')} onMouseLeave={() => this.clear_rendered_menu('form-drop')}>
                                                <p className="pr-drop-par">FORMA</p>
                                            </div>
                                        </th>
                                        <th colspan="2" className="pr_th-right-space" title="Studijní obor">
                                            <input className="pr-inp glyphicon" id="obr-inp" onChange={(event) => { this.filterAllColumns(); }} type="text" placeholder="OBOR &#xe003;"></input>
                                        </th>
                                        <th colspan="2" className="pr_th-right-space" title="Zkatka víceoborového studia.">
                                            <input className="pr-inp glyphicon" id="vic-inp" onChange={(event) => { this.filterAllColumns(); }} type="text" placeholder="VÍCEOBOROVÉ &#xe003;"></input>
                                        </th>
                                        <th colspan="1" className="pr_th-right-space" title="Počet zapsaných stdentů">
                                            <div id="zapsani-drop" className="pr-table-drop-down" onMouseEnter={() => this.render_drop_menu('zapsani-drop')} onMouseLeave={() => this.clear_rendered_menu('zapsani-drop')}>
                                                <p className="pr-drop-par">ZAPSANÍ</p>                                     
                                            </div>
                                        </th>
                                        <th colspan="1" className="pr_th-right-space" title="Počet přjatých stdentů">
                                            <div id="prijati-drop" className="pr-table-drop-down" onMouseEnter={() => this.render_drop_menu('prijati-drop')} onMouseLeave={() => this.clear_rendered_menu('prijati-drop')}>
                                                <p className="pr-drop-par">PŘIJATÍ</p>                                      
                                            </div>
                                        </th>
                                        <th colspan="1" className="pr_th-right-space" title="Počet nepřijatých stdentů">
                                            <div id="neprijati-drop" className="pr-table-drop-down" onMouseEnter={() => this.render_drop_menu('neprijati-drop')} onMouseLeave={() => this.clear_rendered_menu('neprijati-drop')}>
                                                <p className="pr-drop-par">NEPŘIJATÍ</p>                                  
                                            </div>
                                        </th>
                                        <th colspan="1" className="pr_th-right-space" title="Počet studentů, kteří se nedostavil na zápis.">
                                            <div id="nedostavili-drop" className="pr-table-drop-down" onMouseEnter={() => this.render_drop_menu('nedostavili-drop')} onMouseLeave={() => this.clear_rendered_menu('nedostavili-drop')}>
                                                <p className="pr-drop-par">NEDOSTAVILI</p>                                     
                                            </div>
                                        </th>
                                        <th colspan="1" className="pr_th-right-space" title="Celkový počet uchazečů o studium.">
                                            <div id="celkem-drop" className="pr-table-drop-down" onMouseEnter={() => this.render_drop_menu('celkem-drop')} onMouseLeave={() => this.clear_rendered_menu('celkem-drop')}>
                                                <p className="pr-drop-par ap-table-head-margin-last">CELKEM</p>                                      
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <div className="ap-table-pad"></div>
                            </div>
                            <tbody className="pr-tbody" id="pr-tab">
                            {
                                 this.state.filtered_data.map(row => (
                                    <tr className="pr_tr">
                                         <td colspan="2" className="pr_td">{row[0]}</td>
                                         <td colspan="1" className="pr_td">{row[1]}</td>
                                         <td colspan="1" className="pr_td">{row[2]}</td>
                                         <td colspan="1" className="pr_td">{row[3]}</td>
                                         <td colspan="2" className="pr_td">{row[4]}</td>
                                         <td colspan="2" className="pr_td">{row[5]}</td>
                                         <td colspan="1" className="pr_td">{row[6]}</td>
                                         <td colspan="1" className="pr_td">{row[7]}</td>
                                         <td colspan="1" className="pr_td">{row[8]}</td>
                                         <td colspan="1" className="pr_td">{row[9]}</td>
                                         <td colspan="1" className="pr_td">{row[10]}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </ManazerskaData>
        );
    }
}
import React, { Component } from 'react';
import '../../css/tvorbaZprav.css';
import '../../css/manazerskaData.css';
import { Redirect } from 'react-router';
import { Details } from './Details';
import { TvorbaZpravDetail } from './TvorbaZpravDetail';
import { menu_config, headers_config } from './ManazerskaDataConfig'
import DatePicker from 'react-date-picker';
import { return_anual_report } from '../ExportData'
import { Message, Icon } from 'semantic-ui-react'
import { confirmAlert } from 'react-confirm-alert';


export class ManazerskaData extends Component {
    //Pokud komponenta nema zadnou child componentu zobrazi menu s ukazateli. V opacnem pripade primo vyrenderuje child komponentu s ukazatelem. 
    constructor(props) {
        super(props);
        this.state = {
            year: new Date(new Date().getFullYear() - 1, 0),
            details: false,
            export_details: false,
            tvorba_zprav: menu_config,
        };
    }

    expor_anual_report = (menu, header) => { //Vytvori okno a po urceni roku vrati excel s VZ
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div>
                        <Message className="tv-export-msg">
                            <Message.Header>Exportovat</Message.Header>
                            <Message.List>
                                <Message.Item>Vyberte rok výroční zprávy</Message.Item>
                                <DatePicker className="tv-date-picker pr-date-picker"
                                    format='yy'
                                    minDate={new Date(2010, 0)}
                                    maxDate={new Date(new Date().getFullYear() - 1, 0)}
                                    maxDetail="decade"
                                    onChange={(newDate) => { this.state.year = newDate; this.expor_anual_report(menu, header); /*this.onChangeStartDate(newDate, menu, header)*/ }}
                                    value={this.state.year}
                                />
                                <button onClick={() => { return_anual_report(menu_config, header, this.state.year.getFullYear()) }} className="ui button tv-export-message-btn export-message-btn tv-font-msg">Exportovat<Icon disabled name='file excel' className='export-message-btn-icon tv-font-msg' /></button>
                                <button onClick={() => { onClose() }} className="ui button export-message-btn tv-font-msg">Zavřít<Icon disabled name='times circle' className='export-message-btn-icon tv-font-msg' /></button>
                            </Message.List>
                        </Message>
                    </div>
                );
            }
        });
    }

    onChangeStartDate(year, menu, header) { //Musim zavolat zpatky expor_anual_report s novym vybranym rokem
            this.setState({ year: year });
            this.expor_anual_report(menu, header);
    }

    redirect_to_path(event) {
        //Nastavi cestu na presmerovani a skoci na top stranky
        this.setState({
            path: event.target.id
        }, () => {
                var top = window.screen.width >= 768 ? 250 : 0;
                window.scrollTo({ top: top })
                this.setState({ redirect: true })
        })
    }

    toggleDetails = (event, state) => {
        //Zobrazi menu s podrobnostmi
        var item_values;
        if (event.target.value !== undefined) {
            item_values = event.target.value
        }
        var value = !this.state[state];
        this.setState({
            [state]: value, item: item_values
        }, () => {
                var top = window.screen.width >= 768 ? 250 : 0;
                window.scrollTo({ top: top, behavior: `smooth` })
        });
    };

    render_menu() {
        //Vygeneruji tabulku s menu -> obsahuje nazvy kategorii a jednotlive polozky
        var table_content = [];

        for (var menu_item in this.state.tvorba_zprav) {
            table_content.push(
                <tr key={menu_item}>
                    <td colSpan="6">{menu_item}</td>
                    <td></td>
                    <td></td>
                </tr>
            );

            for (var i = 0; i < this.state.tvorba_zprav[menu_item].length; i++) {
                //const detail = !this.state.tvorba_zprav[menu_item][i].hasOwnProperty("number") ? null : <button className="tv-button" onClick={(event) => this.toggleDetails(event, "details")} value={JSON.stringify(this.state.tvorba_zprav[menu_item][i])} title="Podrobnosti"><i className="glyphicon glyphicon-info-sign"></i></button> - {detail}->105
                var row = (
                    <tr key={this.state.tvorba_zprav[menu_item][i]["number"]}>
                        <td className="tv-zprav-menu-num-cell">{this.state.tvorba_zprav[menu_item][i]["number"]}</td>
                        <td>{this.state.tvorba_zprav[menu_item][i]["name"]}</td>
                        <td className="tv-td-btn">
                            <button className="tv-button" onClick={(event) => this.redirect_to_path(event)} id={this.state.tvorba_zprav[menu_item][i]["path"]} title="Zobrazit"><i className="glyphicon glyphicon-eye-open"></i></button>
                            
                        </td>
                    </tr>
                )
                table_content.push(row);
            }
        }

        return (
            <div className="tv-table-wrapper">
                <div className="col-md-8 col-md-offset-2 col-sm-12 ">
                    <table className="tv-zprav-menu-table">
                        <colgroup>
                            <col width="5%"/>
                            <col width="65%"/>
                            <col width="30%"/>
                        </colgroup>
                        <tr>
                            <td colSpan="2">EXPORTOVAT TABULKY PRO VÝROČNÍ ZPRÁVU</td>
                            <td>
                                <button className="tv-button" onClick={() => this.expor_anual_report(this.state.menu, headers_config)} title="Exportovat"><i className="glyphicon glyphicon-cloud-download"></i></button>
                                <button className="tv-button" onClick={(event) => this.toggleDetails(event, "export_details")} title="Podrobnosti"><i className="glyphicon glyphicon-info-sign"></i></button>
                            </td>
                        </tr>
                        {this.state.details ? <Details close={this.toggleDetails} selected_item={this.state.item} /> : null}
                        {table_content}
                    </table>
                    {this.state.export_details ? <TvorbaZpravDetail close={this.toggleDetails} /> : null}
                </div>
            </div>
        )
    }

    return_component_data(path, state) {
        //Projdu menu a vratim pro komponentu jeji konfiguracni data
        for (var menu_item in state) {
            for (var i = 0; i < state[menu_item].length; i++) {
                if (path === state[menu_item][i]["path"]) {
                    return state[menu_item][i];
                }
            }
        }
    }

    render() {
        var child = null;
        //Pokud je children component pak ji zoprazim v opacnem pripade zobrazim menu s tabulkami
        if (this.props.children !== null) {
            var child_data = null;
            var number = null;
            if ("location" in this.props.children.props) {
                var results = this.return_component_data(this.props.children.props.location["pathname"], this.state.tvorba_zprav);
                number = results["number"];
                child_data = results;
            }
            child = React.cloneElement(
                React.Children.only(this.props.children),
                {
                    child_data: child_data,
                    header: headers_config[number]
                }
            );
        }
        else {
            child = this.render_menu();
        }

        if (this.state.redirect) {
            //Pokud push na soucasnou komponentu, pak redirect zrusim.
            if (this.state.path === window.location.pathname) {
                this.setState({ redirect: false }, () => { return });
            }
            return <Redirect push to={this.state.path}  />;
        }
        return (
            <div>
                {child};
            </div>
        );
    }
}
import React, { Component } from 'react';
import DatePicker from 'react-date-picker';
import { ExportMessage } from '../ExportMessage'
import { confirmAlert } from 'react-confirm-alert';
import { return_file } from '../ExportData'


export class ManazerskaDataMenu extends Component {
    //Componenta vytvori pro ukazatele zahlavi obsahujici DatePicker a exportni tlacitko
    constructor(props) {
        super(props);
        this.state = {
            year: new Date(new Date().getFullYear() - 1, 0)
        }
    }

    componentDidMount() {
        this.props.fetchData(this.state.year.getFullYear());
    }

    submit = () => {                                        //Vytvori okno pro vyber mezi exportem pdf excel
        confirmAlert({
            customUI: ({ onClose }) => {
                var year = this.state.year;
                var child_data = this.props.child_data;
                var headers = this.props['headers'];
                var data = this.props.data;

                function confirmCallback(choice) {
                    if (choice == 1) {                      //Byl vybran export do excellu
                        return_file(year, child_data, data, headers, "api/ExcellExport/GenerateExcelFile");
                    }
                    else if (choice == 2) {                  //Byl vybran export do pdf                                                 
                        return_file(year, child_data, data, headers, "api/PDFExport/GeneratePDFFile");              
                    }
                    onClose()                               //Zavru confirm window
                }
                return (
                    <ExportMessage confirmCallback={confirmCallback} />
                )
            }
        });
    };

    onChangeStartDate = year => {
        //POST pro nove data po zmene zadaneho roku 
        this.setState({ year }, () => {
            this.props.fetchData(this.state.year.getFullYear());
        })
    }
        
    render() {
        return (
                <div className="row pr-head-div">
                    <div className="col-md-2 pr-head-sel-margin">
                        <label htmlFor="yearStart" className="pr-label" title="Rok přijímacího řízení.">ROK</label>
                        <DatePicker className="pr-date-picker"
                            id="yearStart"
                            format='yy'
                            minDate={new Date(2010, 0)}
                            maxDate={new Date(new Date().getFullYear() - 1, 0)}
                            maxDetail="decade"
                            onChange={this.onChangeStartDate}
                            value={this.state.year}
                        />
                    </div>
                    <div className="col-md-2 col-xs-12">
                        <input type="button" className="pr-exp-btn" value="EXPORTOVAT" onClick={this.submit} />
                    </div>
                </div>
            );
    }    
}
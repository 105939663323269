import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import { Navbar, NavDropdown, NavItem, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/routeMenu.css';
import { Link } from 'react-router-dom';


export class RouteMenu extends Component {
    render() { 
        return (
            <div className="route-navbar">
                <Navbar inverse fluid collapseOnSelect>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse>
                        <Nav className="route-nav-div">
                            <NavItem className="route-nav-link">
                                <Link to="/editace-ukazatelu"><button className="route-nav-btn">EVIDENCE UKAZATELŮ</button></Link>
                            </NavItem>
                            <NavItem className="route-nav-sep">
                                <span className="route-svg-span">
                                    <svg height="60" width="40">
                                        <line x1="22" x2="12" y1="20" y2="40" stroke="white" strokeWidth="3" />
                                        <line x1="30" x2="20" y1="20" y2="40" stroke="white" strokeWidth="3" />
                                    </svg>
                                </span>
                            </NavItem>
                            <NavItem className="route-menu-link route-item">
                                <Link to="/manazerska_data"><button className="route-nav-btn">MANAŽERSKÉ PŘEHLEDY</button></Link>
                            </NavItem>
                            <NavItem className="route-nav-sep">
                                <span className="route-svg-span">
                                    <svg height="60" width="40">
                                        <line x1="22" x2="12" y1="20" y2="40" stroke="white" strokeWidth="3" />
                                        <line x1="30" x2="20" y1="20" y2="40" stroke="white" strokeWidth="3" />
                                    </svg>
                                </span>
                            </NavItem>
                            <NavItem className="route-nav-link">
                                <Link to="/home"><button className="route-nav-btn">O APLIKACI</button></Link>
                            </NavItem>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                
            </div>
        );
    }
}
import React, { Component } from 'react';


export class Podrobnosti extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='col-md-8 col-md-offset-2 home-font home-height-pad'>
                <br />
                <h4>Zaevidování nového ukazatele</h4>
                <div>
                    V menu záhlaví aplikace zvolte evidenci ukazatelů. Aplikace zobrazí tabulku zaevidovaných ukazatelů. Na konci tabulky pod ukazateli zvolte tlačítko Nový. Zobrazí se nám formulář pro zaevidování ukazatele. Po vyplnění formuláře zvolte tlačítko ve spodní části Uložit. Pokud byly vyplněny všechny povinné položky, pak bude ukazatel uložen.
                </div>
                <br />
                <h4>Upravení zaevidovaného ukazatele</h4>
                <div>
                    V menu záhlaví aplikace zvolte evidenci ukazatelů. V tabulce najděte ukazatel, který chcete pozměnit. Zvolte tlačítko upravit. Zobrazí se formulář s uloženými údaji. Upravte položky, které chcete změnit. Následně uložte změny zvolením tlačítka uložit.
                </div>
                <br />
                <h4>Smazat zaevidovaný ukazatel</h4>
                <div>
                    V menu záhlaví aplikace zvolte evidenci ukazatelů. Následně zvolte tlačítko smazat a potvrďte vaši volbu.
                </div>
                <br />
                <h4>Zobrazení ukazatele</h4>
                <div>
                    V menu aplikace vyberte manažerské přehledy. Následně se zobrazí seznam implementovaných ukazatelů. Najděte v seznamu požadovaný ukazatel. Po zvolení tlačítka zobrazit u daného ukazatele, budete přesměrováni na stránku ukazatele. Záhlaví ukazatelů obsahuje kalendář pro filtrování záznamů, popřípadě u některých ukazatelů je možné filtrovat podle dalších kritérií. Po vybrání požadovaného roku se zobrazí výstupy v tabulce ukazatele.
                </div>
                <br />
                <h4>Exportování ukazatele</h4>
                <div>
                    V záhlaví vybraného ukazatele vyberte tlačítko exportovat. Následně v menu vyberte formát pdf nebo xlsx.
                </div>
                <br />
                <h4>Stáhnutí prázdné šablony ukazatele</h4>
                <div>
                    V menu aplikace vybereme manažerské přehledy. Najdeme požadovaný ukazatel a zvolíme tlačítko podrobnosti. Zobrazí se nám menu s podrobnostmi ukazatele. Šablonu stáhneme kliknutím na odkaz u položky stáhnout šablonu.
                </div>
                <br />
                <h4>Exportování výroční zprávy</h4>
                <div>
                    V záhlaví aplikace zvolíme manažerské přehledy. V menu nad seznamem ukazatelů najdeme položku exportovat tabulky VZ. Vybereme tlačítko exportovat. Následně zvolíme požadovaný rok pro výroční zprávu. Následně se stáhne soubor ve formátu xlsx.
                </div>
                <br />
                <h4>Zobrazení tabulek výroční zprávy</h4>
                <div>
                    V záhlaví aplikace zvolíme manažerské přehledy. V menu nad seznamem ukazatelů najdeme položku exportovat tabulky VZ. Zvolíme tlačítko podrobnosti. Zobrazí se nám seznam implementovaných ukazatelů obsažených ve výroční zprávě.
                </div>
                <br />
                <div>
                    Repozitář projektu: 
                     <a className="footer-link" href="https://gitlab.slu.cz/fil0007/amok.git">­ https://gitlab.slu.cz/fil0007/amok.git</a>
                </div>
                <br />
                <br />
                <br />
            </div>
        );
    }
}
import React, { Component } from 'react';
import { menu_config } from '../ManazerskaData/ManazerskaDataConfig';
import { Redirect } from 'react-router';


export class TvorbaZpravDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            year: new Date(),
            menu: menu_config,
            redirect: false
        }
    }

    redirect_to_path(event) {
        this.setState({ path: event.target.id, redirect: true })
    }

    render() {
        var content = [];

        for (var menu_item in this.state.menu) {
            for (var i = 0; i < this.state.menu[menu_item].length; i++) {
                if (!this.state.menu[menu_item][i].hasOwnProperty("number"))          //Ukazatel neni soucasti vyrocni zpravy
                    continue;

                var row = <tr id={this.state.menu[menu_item][i]["path"]}>
                    <td><p className="man-detail-font">{this.state.menu[menu_item][i]["name"]}</p></td>
                    <td><button className="tv-button" onClick={(event) => this.redirect_to_path(event)} id={this.state.menu[menu_item][i]["path"]} title="Zobrazit"><i className="glyphicon glyphicon-eye-open"></i></button></td>
                </tr>
                content.push(row);
            }
        }

        if (this.state.redirect) {
            //Pokud push na soucasnou komponentu, pak redirect zrusim.
            return <Redirect push to={this.state.path} />;
        }

        return (
            <div className="tv-zprav-details-main-div">
                <div className="col-md-8 col-md-offset-2 coll-sm-12 tv-detail-form">
                    <span class="glyphicon glyphicon-remove-sign tv-zprav-details-close" aria-hidden="true" onClick={(event) => this.props.close(event, "export_details")}></span>
                    <h3 className="tv-detail-header">Obsah výroční zprávy</h3>
                    <br />
                    <table className="tv-zprav-menu-table">
                        <colgroup>
                            <col width="80%" />
                            <col width="20%" />
                        </colgroup>
                        {content}
                    </table>
                </div>
            </div>
        );
    }
}
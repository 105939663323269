import React, { Component } from 'react';
import { ManazerskaData } from './ManazerskaData';
import { ManazerskaDataMenu } from './ManazerskaDataMenu';


export class StrukturaZamestnancu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    fetchData(year) {
        const fetch_location = this.props.child_data["data_location"];
        fetch(fetch_location, {
            method: 'POST',
            dataType: "json",
            body: JSON.stringify({ "year": year }),
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'), // Add the authentication header
                "Accept": "application/json"
            },
        }).then(response => response.json())
            .then(data => {
                this.setState({ "data": data });
            });;
    }

    create_table_headers(table_headers) {
        //Vytvori zahlavi pro jednotlive tabulky
        var headers = {};
        for (var table in this.state.data) {
            var deepCopyObj = JSON.parse(JSON.stringify(table_headers)); 
            deepCopyObj["table_headers"][0][0].text = table
            headers[table] = deepCopyObj;
        }

        return headers;
    }

    render_table() {
        //Vytvori komplet tabulku
        var tab = [];
        for (var table in this.state.data) {
            tab.push(this.render_table_head(table))
            tab.push(this.render_table_body(this.state.data[table]));
        }
        return tab;
    }

    render_table_head = faculty => {
        //Vrati zahlavi tabulky
        var head = <thead className="ap-table-header">
            <tr>
                <th rowspan="3" colspan="2" scope="colgroup">{faculty}</th>
                <th colspan="14" scope="colgroup">Akademičtí pracovníci</th>
                <th colspan="6" scope="colgroup">Vědečtí a odborní pracovníci</th>
                <th rowspan="2" colspan="2" scope="colgroup">Ostatní zaměstnanci</th>
                <th rowspan="3" colspan="1" scope="colgroup">CELKEM</th>
                <th rowspan="3" colspan="1" scope="colgroup">z toho ženy</th>
            </tr>
            <tr>
                <th colspan="2">Profesoři</th>
                <th colspan="2">Docenti</th>
                <th colspan="2">Odborní asistenti</th>
                <th colspan="2">Asistenti</th>
                <th colspan="2">Lektoři</th>
                <th colspan="2">Vědečtí pracovníci <i class="ap-table-glyph glyphicon glyphicon-question-sign" title="Vědečtí, výzkumní a vývojoví pracovníci podílející se na pedagog. činnosti"></i></th>
                <th colspan="2">Mimořádní profesoři</th>
                <th colspan="2">Post doktorandi</th>
                <th colspan="2">Vědečtí pracovníci</th>
                <th colspan="2">Ostatní pracovníci <i class="ap-table-glyph glyphicon glyphicon-question-sign" title="Ostatní vědečtí, výzkumní a vývojoví pracovníci"></i></th>
            </tr>
            <tr>
                <th colspan="1">CELKEM</th>
                <th colspan="1">ženy</th>
                <th colspan="1">CELKEM</th>
                <th colspan="1">ženy</th>
                <th colspan="1">CELKEM</th>
                <th colspan="1">ženy</th>
                <th colspan="1">CELKEM</th>
                <th colspan="1">ženy</th>
                <th colspan="1">CELKEM</th>
                <th colspan="1">ženy</th>
                <th colspan="1">CELKEM</th>
                <th colspan="1">ženy</th>
                <th colspan="1">CELKEM</th>
                <th colspan="1">ženy</th>
                <th colspan="1">CELKEM</th>
                <th colspan="1">ženy</th>
                <th colspan="1">CELKEM</th>
                <th colspan="1">ženy</th>
                <th colspan="1">CELKEM</th>
                <th colspan="1">ženy</th>
                <th colspan="1">CELKEM</th>
                <th colspan="1">ženy</th>
            </tr>
        </thead>

        return head;
    }

    render_table_body = table => {
        //Vrati obsah tabulky
        var body = <tbody className="ac-tbody-multiple" id="ap-uk-tab">
            {table.map(row => (
                <tr className="pr_tr ed-uk-tr">
                    <td colspan="2" className="pr_td">{row[0]}</td>
                    <td colspan="1" className="pr_td">{row[1]}</td>
                    <td colspan="1" className="pr_td">{row[2]}</td>
                    <td colspan="1" className="pr_td">{row[3]}</td>
                    <td colspan="1" className="pr_td">{row[4]}</td>
                    <td colspan="1" className="pr_td">{row[5]}</td>
                    <td colspan="1" className="pr_td">{row[6]}</td>
                    <td colspan="1" className="pr_td">{row[7]}</td>
                    <td colspan="1" className="pr_td">{row[8]}</td>
                    <td colspan="1" className="pr_td">{row[9]}</td>
                    <td colspan="1" className="pr_td">{row[10]}</td>
                    <td colspan="1" className="pr_td">{row[11]}</td>
                    <td colspan="1" className="pr_td">{row[12]}</td>
                    <td colspan="1" className="pr_td">{row[13]}</td>
                    <td colspan="1" className="pr_td">{row[14]}</td>
                    <td colspan="1" className="pr_td">{row[15]}</td>
                    <td colspan="1" className="pr_td">{row[16]}</td>
                    <td colspan="1" className="pr_td">{row[17]}</td>
                    <td colspan="1" className="pr_td">{row[18]}</td>
                    <td colspan="1" className="pr_td">{row[19]}</td>
                    <td colspan="1" className="pr_td">{row[20]}</td>
                    <td colspan="1" className="pr_td">{row[21]}</td>
                    <td colspan="1" className="pr_td">{row[22]}</td>
                    <td colspan="1" className="pr_td">{row[23]}</td>
                    <td colspan="1" className="pr_td">{row[24]}</td>
                </tr>
            ))}
        </tbody>
        return body;     
    }

    render() {   
        const header = this.create_table_headers(this.props.header);

        return (
            <ManazerskaData title="Věková struktura zaměstnanců">
                <div>
                    <ManazerskaDataMenu fetchData={this.fetchData.bind(this)} headers={header} data={this.state.data} child_data={this.props.child_data} />
                    <div className="ac-table-div"> 
                        <table className='ap-table-auto-position-max col-sm-12'>
                            {this.render_table()}
                        </table>
                    </div>
                </div>
            </ManazerskaData>        
        );
    }
}
import React, { Component } from 'react';
import '../../css/uspesnostStudia.css';
import DatePicker from 'react-date-picker';
import { ManazerskaData } from '../ManazerskaData/ManazerskaData';
import { headers_config } from '../ManazerskaData/ManazerskaDataConfig';
import { ExportMessage } from '../ExportMessage'
import { confirmAlert } from 'react-confirm-alert';
import { return_file } from '../ExportData'


export class UspesnostStudia extends Component {
    constructor(props) {
        super(props);
        this.state = { data: [], fakulta: [], typStudia: [], typ_options: [], fak_options: [], oboryStudia: [], studium: [], startDate: new Date(), endDate: new Date() };
    }
    componentDidMount() {
        this.renderSelect();
        this.fetchData();
    }
    
    renderSelect() {
        fetch("api/UspesnostStudia/GetFieldOfStudy", {
            method: 'POST',
            dataType: "json",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Accept": "application/json"
            },
        }).then(response => response.json())
            .then(data => {
                data["Fakulta"].map(element => {
                    this.setState({
                        fak_options: this.state.fak_options.concat([[element[0], element[1]]])
                    })
                    });
                data["Typ"].map(element => {
                    var ids = ['p', 'm', 'b', 'n'];
                    var title = ['Doktorské', 'Magisterské', 'Bakalářské', 'Navazující'];
                    if (ids.includes(element[0].toLowerCase())) {
                        var index = ids.indexOf(element[0].toLowerCase());
                        element[0] = title[index];
                    }
                    this.setState({
                        typ_options: this.state.typ_options.concat([[element[0], element[0]]])
                    })
                });
            });;
    }

    submit = () => {                                        //Vytvori okno pro vyber mezi exportem pdf excel
        confirmAlert({
            customUI: ({ onClose }) => {
                var child_data = this.props.child_data;
                var headers = headers_config["uspesnost_studia"];
                var data = this.state.data;

                function confirmCallback(choice) {
                    if (choice == 1) {                      //Byl vybran export do excellu
                        return_file(new Date(), child_data, data, headers, "api/ExcellExport/GenerateExcelFile", false);
                    }
                    else if (choice == 2) {                  //Byl vybran export do pdf                                                 
                        return_file(new Date(), child_data, data, headers, "api/PDFExport/GeneratePDFFile", false);
                    }
                    onClose()                               //Zavru confirm window
                }
                return (
                    <ExportMessage confirmCallback={confirmCallback} />
                )
            }
        });
    };

    fetchData() {
        //z fakulty a typu studia vyberu pouze id
        var post_data = { "fakulta": this.state.fakulta.map(x=> x[0]), "typStudia": this.state.typStudia.map(x => x[0]), "startDate": this.state.startDate.getFullYear(), "endDate": this.state.endDate.getFullYear()};
        fetch(this.props.child_data["data_location"], {
            method: 'POST',
            dataType: "json",
            body: JSON.stringify(post_data),
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Accept": "application/json"
            },
        }).then(response => response.json())
            .then(data => {
                this.setState({ "data": data });
            });;
    }

    roll_down_menu(data, keys, selected_state_name, menu_id) {
        //Vytvori div s listem a prida ho do parrent div
        var drop_menu = document.getElementById(menu_id);
        var div = document.createElement("div");
        div.classList = "pr-drop-div";
        div.id = menu_id + "_sub_div";
        var ul = document.createElement("ul");
        for (var i = 0; i < data.length; i++) {
            var li = document.createElement("li");
            li.innerText = data[i];
            li.id = keys[i];
            li.addEventListener("click", (event) => this.select_drop_item(event, selected_state_name, menu_id));
            ul.appendChild(li)
        }
        div.append(ul)
        drop_menu.appendChild(div);
    }

    roll_back_menu(element) {
        //Smaze child div z rolldown menu
        var drop_menu = document.getElementById(element);
        if (drop_menu !== null) {
            drop_menu.remove();
        }
    }

    select_drop_item(event, state_name, drom_menu_div) {
        //Pokud je polozka v state pak ji odstrani a zavola pro nova data pokud ne pak prida do state a zavola data
        var state = this.state[state_name];
        var id = event.target.id;
        var name = event.target.innerText;
        var drop_div = document.getElementById(drom_menu_div);
        drop_div.innerHTML = "";

        if (state.map(x => x[0]).includes(id)) {
            const index = state.map(x => x[0]).indexOf(id);
            if (index > -1) {
                state.splice(index, 1);
            }
        }
        else {
            state.push([id, name]);
        }
        this.setState({ [state_name]: state }, () => {
            this.fetchData();
            if (state.length === 0) {                   //Pokud je state prazdny pak prida do prazdny paragraph do div
                var p = document.createElement("p");
                var glyph = document.createElement("i");
                glyph.classList = "glyphicon glyphicon-chevron-right pr-drop-glyph";
                p.appendChild(glyph);
                p.classList = "pr-paragraph";
                drop_div.appendChild(p);
                return;
            }
            for (var i = 0; i < state.length; i++) {
                drop_div.innerHTML += state[i][1] + " ";
            }
        });
    }

    onChangeStartDate = startDate => this.setState({ startDate }, () => { this.fetchData() });
    onChangeEndDate = endDate => this.setState({ endDate }, () => { this.fetchData() })
    
    render() {            
        return (
            <ManazerskaData title="Úspěšnost studia">
            <div>
                    <div className="row pr-head-div">
                            <div className="col-md-2 pr-head-sel-margin us-sel-list">
                                <label className="pr-label">FAKULTA</label>
                            <div className="pr-list arrow" id="fak" onMouseEnter={() => this.roll_down_menu(this.state.fak_options.map(x => x[0]), this.state.fak_options.map(x => x[0]), 'fakulta', 'fak')} onMouseLeave={() => this.roll_back_menu('fak_sub_div')}><p className="pr-paragraph">VŠE <i className="glyphicon glyphicon-chevron-right pr-drop-glyph"></i></p></div>
                            </div>
                            <div className="col-md-2 pr-head-sel-margin us-sel-list">
                                <label className="pr-label">TYP STUDIA</label>
                                <div className="pr-list arrow" id="typ" onMouseEnter={() => this.roll_down_menu(this.state.typ_options.map(x => x[1]), this.state.typ_options.map(x => x[0]), 'typStudia', 'typ')} onMouseLeave={() => this.roll_back_menu('typ_sub_div')}><p className="pr-paragraph">VŠE <i className="glyphicon glyphicon-chevron-right pr-drop-glyph"></i></p></div>
                            </div>
                            <div className="col-md-2 pr-head-sel-margin">
                                <label htmlFor="yearStart" className="pr-label us-sel-list">POČÁTEČNÍ ROK</label>
                                <DatePicker className="pr-date-picker"
                                    id="yearStart"
                                    format='yy'
                                    minDate={new Date(1991, 0)}
                                    maxDate={new Date()}
                                    maxDetail="decade"
                                    onChange={this.onChangeStartDate}
                                    value={this.state.startDate}
                                />
                            </div>
                            <div className="col-md-2 pr-head-sel-margin">
                                <label htmlFor="yearStart" className="pr-label us-sel-list">KONEČNÝ ROK</label>
                                <DatePicker className="pr-date-picker"
                                    id="yearStart"
                                    format='yy'
                                    minDate={new Date(1991, 0)}
                                    maxDate={new Date()}
                                    maxDetail="decade"
                                    onChange={this.onChangeEndDate}
                                    value={this.state.endDate}
                                />
                            </div>
                            <div className="col-md-2 col-xs-12">
                            <input type="button" className="pr-exp-btn" value="EXPORTOVAT" onClick={this.submit} />
                            </div>
                    </div>                  
                    <div className="ac-table-div-auto us-tab-wdith"> 
                        <table className='ap-table-auto-position col-sm-12'>
                            <div className="ap-head-div">
                                <thead className="ap-table-header-scrollbar">
                                    <tr className="us-header-height">
                                        <th rowspan="1" scope="colgroup">ROK</th>
                                        <th rowspan="1" scope="colgroup" title="Počet studentů prvního ročníku.">POČTET PRVÁKŮ</th>
                                        <th rowspan="1" scope="colgroup" title="Počet úspěšných studentů prvních ročníků.">ÚSPĚŠNÝCH PRVÁKŮ</th>
                                        <th rowspan="1" scope="colgroup">POČET ABSOLVENTŮ</th>
                                        <th rowspan="1" scope="colgroup" title="Počet aktivních studií v daném roce.">POČET STUDIÍ</th>
                                        <th rowspan="1" scope="colgroup" title="Počet aktivních studií v daném roce.">NEUKONČENÁ STUDIA</th>
                                    </tr>
                                </thead>
                                <div className="ap-table-pad"></div>
                            </div>
                                <tbody className="ac-tbody" id="ap-uk-tab">
                                    {
                                        this.state.data.map(row => (
                                            <tr className="pr_tr ed-uk-tr">
                                                <td className="pr_td">{row[0]}</td>
                                                <td className="pr_td">{row[1]}</td>
                                                <td className="pr_td">{row[2]}</td>
                                                <td className="pr_td">{row[3]}</td>
                                                <td className="pr_td">{row[4]}</td>
                                                <td className="pr_td">{row[5]}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                        </table>                        
                    </div>
                </div>
            </ManazerskaData>
        );
    }
}
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../css/footer.css';


export class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = { year: new Date() };

    }

    render() {
        return (
            <div className="footer-div">
                <span className="footer-marg-center">
                    <span className="footer-bold ">Copyright © {this.state.year.getFullYear() + " "}</span>
                    <a className="footer-link" href="https://www.slu.cz/slu/cz/citonas">Centrum informačních technologií­</a>&nbsp;                 
                </span>
            </div>
            );
    }
}

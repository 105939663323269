import React, { Component } from 'react';
import { Message, Icon } from 'semantic-ui-react'
import '../css/exportMessage.css';


export class ExportMessage extends Component {
    constructor(props) {
        super(props);
        this.handleButtonClick = this.handleButtonClick.bind(this);
    }

    handleButtonClick(choice) {
        this.props.confirmCallback(choice);
    }

    render() {
        return (
            <div>
                <Message>
                    <Message.Header>Exportovat</Message.Header>
                    <Message.List>
                        <Message.Item>Vyberte formát do kterého chcete exportovat výstupy</Message.Item>
                        <button onClick={() => this.handleButtonClick(1)} class="ui button export-message-btn">Excel<Icon disabled name='file excel' className='export-message-btn-icon' /></button>
                        <button onClick={() => this.handleButtonClick(2)} class="ui button export-message-btn">PDF<Icon disabled name='file pdf' className='export-message-btn-icon' /></button>
                        <button onClick={() => this.handleButtonClick(3)} class="ui button export-message-btn">Žádný<Icon disabled name='times circle' className='export-message-btn-icon' /></button>
                    </Message.List>
                </Message>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import '../css/login.css';
import logo from '../images/login/Logo SU.png';
import titul from '../images/login/titul_mensi_login.png';
import cro_img from '../images/login/CRO.png'
import eu_logo from '../images/eu_logo.jpg'
import ReactDOM from 'react-dom';

export default class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            username: '',
            password: '',
            redirectToReferrer: false,
            year: new Date()
        }

        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        document.body.classList.add('log-background-set');
    }
    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async onSubmit(event) {
        event.preventDefault();
        const CRO = event.target.username.value;
        const password = event.target.password.value;
        let response = await fetch('api/Login/LdapLogin', {
            method: 'POST',
            dataType: "json",
            body: JSON.stringify({
                username:CRO,
                password: password
            }),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        try {
            let data = await response.json();
            if (typeof data.token !== 'undefined') {
                const json = data;
                const token = json.token;
                localStorage.setItem('token', token);
                this.setState({
                    redirectToReferrer: true
                })
            }
            else {
                ReactDOM.render(<div className="col-md-12 login-div"><span className="login_err_msg">Nelze se přihlásit!</span></div>, document.getElementById('err_masg'));
            }
        } catch (e) {
            ReactDOM.render(<div className="col-md-12 login-div"><span className="login_err_msg">Nelze se přihlásit!</span></div>, document.getElementById('err_masg'));
        }
    }
     
    render() { 
        const redirectToReferrer = this.state.redirectToReferrer;
        if (redirectToReferrer === true) {
            return <Redirect to="/home" />
        }
        return (
            <div className="container"> 
                <div className="login-head">
                    <img src={logo} className="login-head-img" />
                    <img src={eu_logo} className="login-eu-logo" />
                </div>
                <div className="login-main-div ">
                    <div className="panel-default">
                        <img src={titul} alt="" className="login-img login-app-title" />
                        <form onSubmit={this.onSubmit}>
                            <div className="login-div login-header-div">
                                <h2 className="login-app-header">Aplikace monitoringu kvality</h2>
                            </div>
                            <div className="login-div">
                                <input type="text" className="login-input" placeholder="CRO" name="username" value={this.state.username} onChange={this.onChange} />
                            </div>
                            <div className="login-div">
                                <input type="password" className="login-input" placeholder="Heslo" name="password" value={this.state.password} onChange={this.onChange} />
                            </div>
                            <div className="login-div login-submit-div col-md-12">
                                <div className="login-logo-btn">
                                    <span className="login-img-span">
                                        <img src={cro_img} className="login-img" />
                                    </span>
                                    <input type="submit" className="login-submit-btn" value="PŘIHLÁSIT" />
                                </div>
                            </div>
                            <div id="err_masg"></div>
                        </form>
                    </div>
                </div>
                <div className="login-footer">
                    <div className="login-footer-sub-div"> Copyright © {this.state.year.getFullYear() + " "}
                        <a className="footer-link" href="https://www.slu.cz/slu/cz/citonas">Centrum informačních technologií­</a></div>
                </div>
            </div> 
        );
    }
}


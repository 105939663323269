import React, { Component } from 'react';
import { ManazerskaData } from './ManazerskaData';
import { ManazerskaDataMenu } from './ManazerskaDataMenu';


export class Stipendia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }
       
    fetchData(year) {
        fetch(this.props.child_data["data_location"], {
            method: 'POST',
            dataType: "json",
            body: JSON.stringify({ "year": year }),
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'), // Add the authentication header
                "Accept": "application/json"
            },
        }).then(response => response.json())
            .then(data => {
                this.setState({"data": data });
            });;
    }


    render() {
        return (
            <ManazerskaData title="Stipendia studentům podle účelu stipendia">
                <div>
                    <ManazerskaDataMenu fetchData={this.fetchData.bind(this)} tb_number={this.props.table_number} headers={this.props.header} data={this.state.data} child_data={this.props.child_data} />
                    <div className="ac-table-div">
                        <table className='col-sm-12'>
                            <thead className="ap-table-header">
                                <tr>
                                    <th rowspan="1" colspan="3" scope="colgroup">Vysoká škola (název)</th>
                                    <th rowspan="2" colspan="2" scope="colgroup">Počty studentů</th>
                                    <th rowspan="2" colspan="2" scope="colgroup">Průměrná výše stipendia</th>
                                </tr>
                                <tr>
                                    <th colspan="3">Účel stipendia</th>                                 
                                </tr>
                            </thead>
                            <tbody className="ac-tbody" id="ap-uk-tab">
                                {
                                    this.state.data.map((row) => (
                                        <tr className="pr_tr ed-uk-tr">
                                            <td colspan="3" className="pr_td">{row[0]}</td>
                                            <td colspan="2" className="pr_td">{row[1]}</td>
                                            <td colspan="2" className="pr_td">{row[2]}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </ManazerskaData>
        );
    }
}

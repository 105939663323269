import React, { Component } from 'react';
import '../../css/evidenceUkazatelu.css';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-date-picker';


export class EvidenceUkazatelu extends Component {
    state = {
        jina_oblast_uk: "", jine_cis_uk: "", jiny_zdroj_uk: "", year: new Date().getFullYear(),
        dateUkony: new Date(new Date().getFullYear(), 0, 1), dateVykaz: new Date(new Date().getFullYear(), 0, 1), dateVypocet: new Date(new Date().getFullYear(), 0, 1), datePodklady: new Date(new Date().getFullYear(), 0, 1)
    } 
    constructor(props) {
        super(props);
        this.setStateList = this.setStateList.bind(this);
        this.saveData = this.saveData.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.setStateTxt = this.setStateTxt.bind(this);
        this.checkSelectedOption = this.checkSelectedOption.bind(this);       
    }

    saveData() {
        //Overuje duplicitu zamezi vlozeni stejneho zaznamu do zaznamniku
        var select_data = ["oblast", "ukazatel", "zdroj"];
        var select_state_others = [this.state.jina_oblast_uk, this.state.jine_cis_uk, this.state.jiny_zdroj_uk];
        var select_state = [this.state.oblast, this.state.ukazatel, this.state.zdroj];

        for (var i = 0; i < select_data.length; i++) {
            if (this.checkDuplicity(select_data[i], select_state_others[i])) {
                var div = document.getElementById("ev-uk-resp");
                div.classList.add("ev-uk-resp");
                div.innerHTML = select_data[i] + " už je v číselníku.";
                return;
            }
        }

        //Overi ze definice input je vyplnen
        if (!this.state.definice_uk || this.state.definice_uk.length === 0) {
            var div = document.getElementById("ev-uk-resp");
            div.classList.add("ev-uk-resp");
            div.innerHTML = "Ukazatel musí být definován.";
            return;
        }

        //Overi jestli je definovana jina oblast, zdroj, nebo cislo pokud je vybrane others
        for (var i = 0; i < select_state.length; i++) {
            if (select_state[i] === "others" && (!select_state_others[i] || select_state_others[i].length === 0)) {
                var div = document.getElementById("ev-uk-resp");
                div.classList.add("ev-uk-resp");
                div.innerHTML = select_data[i] + " musí být definován.";
                return;
            }
        }

        //Overi jestli existuje ulozeny ukazatel
        if (this.props.questionaireID) {
            this.updateSavedQuestionaire();
        }
        else {
            this.saveNewQuestionaire();
        }
    }

    saveNewQuestionaire() {
        //Ulozi novy ukazatel
        fetch('api/Evidence/SaveQuestionnaire', {
            method: 'POST',
            dataType: "json",
            body: JSON.stringify({
                "oblast": this.state.oblast, "PRAC_NAZEV": this.state.prac_nazev, "zdroj": this.state.zdroj, "ukazatel": this.state.ukazatel, "UKAZATEL_DEF": this.state.definice_uk, "OSOBA_ZODP": this.state.odpovedna_osoba, "VYPOCET_POPIS": this.state.vzorec, "POPIS_JINY_UKON": this.state.jine_ukony, "PRIJEMCE_VYKAZU": this.state.prijemce_vykazu,
                "OSOBA_OPRAVNENA": this.state.opravnene_osoby, "DOPORUC_POHLEDY": this.state.pohledy, "POZN": this.state.poznamky, "GARANT": this.state.garant, "FORMA_VYKAZU": this.state.forma_vykazu,
                "DAT_SBER_OD": this.state.datePodklady_from, "DAT_SBER_DO": this.state.datePodklady_to, "DAT_ZPRAC_OD": this.state.dateVypocet_from, "DAT_ZPRAC_DO": this.state.dateVypocet_to, "DAT_VYKAZ_OD": this.state.dateVykaz_from, "DAT_VYKAZ_DO": this.state.dateVykaz_to, "DAT_JINY_UKON_OD": this.state.dateUkony_from, "DAT_JINY_UKON_DO": this.state.dateUkony_to,
                "jina_oblast_uk": this.state.jina_oblast_uk, "jine_cis_uk": this.state.jine_cis_uk, "jiny_zdroj_uk": this.state.jiny_zdroj_uk
            }),
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'), // Add the authentication header
                "Accept": "application/json"
            },
        }).then(response => response.json())
            .then(data => {
                if (data) {
                    this.props.callbackFromParent(true)
                }
            });
    }

    updateSavedQuestionaire() {
        //Upravi existujici ulozeny ukazatel
        fetch('api/Evidence/UpdateQuestionnaire', {
            method: 'POST',
            dataType: "json",
            body: JSON.stringify({
                "id": this.props.questionaireID,
                "oblast": this.state.oblast, "PRAC_NAZEV": this.state.prac_nazev, "zdroj": this.state.zdroj, "ukazatel": this.state.ukazatel, "UKAZATEL_DEF": this.state.definice_uk, "OSOBA_ZODP": this.state.odpovedna_osoba, "VYPOCET_POPIS": this.state.vzorec, "POPIS_JINY_UKON": this.state.jine_ukony, "PRIJEMCE_VYKAZU": this.state.prijemce_vykazu,
                "OSOBA_OPRAVNENA": this.state.opravnene_osoby, "DOPORUC_POHLEDY": this.state.pohledy, "POZN": this.state.poznamky, "GARANT": this.state.garant, "FORMA_VYKAZU": this.state.forma_vykazu,
                "DAT_SBER_OD": this.state.datePodklady_from, "DAT_SBER_DO": this.state.datePodklady_to, "DAT_ZPRAC_OD": this.state.dateVypocet_from, "DAT_ZPRAC_DO": this.state.dateVypocet_to, "DAT_VYKAZ_OD": this.state.dateVykaz_from, "DAT_VYKAZ_DO": this.state.dateVykaz_to, "DAT_JINY_UKON_OD": this.state.dateUkony_from, "DAT_JINY_UKON_DO": this.state.dateUkony_to,
                "jina_oblast_uk": this.state.jina_oblast_uk, "jine_cis_uk": this.state.jine_cis_uk, "jiny_zdroj_uk": this.state.jiny_zdroj_uk
            }),
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'), // Add the authentication header
                "Accept": "application/json"
            },
        }).then(response => response.json())
            .then(data => {
                if (data) {
                    this.props.callbackFromParent(true)
                }
            });
    }

    checkDuplicity(select_id, text) {
        //Zkontroluji jestli definovane jine cislo, oblast, nebo ukazatel jiz neni v ciselniku
        var select = document.getElementById(select_id);
        for (var i = 0; i < select.options.length; i++) {
            if (text.toLowerCase() === select.options[i].innerText.toLowerCase() && select.options[select.selectedIndex].value === 'others') {
                return true;
            }
        }
        return false;
    }
       
    setStateList(event, others_state_name) {
        //Nastavi nevo vybrany option selectu do state 
        var state = event.target.name;
        state = state.split('-')[0];
        this.setState({ [state]: event.target.value });
        var element = document.getElementById(event.target.name);
        if (event.target.value === 'others')
            element.style.display = 'block';
        else {
            this.state[others_state_name] = "";         //Smaze jiny zdroj, oblast nebo cislo pokud je vybran jeden ze selectu
            element.style.display = 'none';
        }
    }

    setStateTxt(event) {
        this.setState({ [event.target.id]: event.target.value });
    }

    changeDate(state, e) {
        this.setState({ [state]: e });
    }

    componentDidMount() {
        //Nacte optons do select oblast, zdroj, ukazatel
        var text_data = ['definice_uk', 'prac_nazev', 'odpovedna_osoba', 'garant', 'vzorec', 'jine_ukony', 'prijemce_vykazu', 'forma_vykazu', 'opravnene_osoby', 'pohledy', 'poznamky', 'jina_oblast_uk', 'jine_cis_uk', 'jiny_zdroj_uk'];
        var select_data = ["oblast", "ukazatel", "zdroj"];
        var date_data = ['datePodklady_from', 'datePodklady_to', 'dateVypocet_from', 'dateVypocet_to', 'dateVykaz_from', 'dateVykaz_to', 'dateUkony_from', 'dateUkony_to'];

        this.createSelectOptions(select_data, text_data, date_data);     
    }

    createSelectOptions(select_data, text_data, date_data) {
        //Pro selekty vratim vsechny options
        fetch('api/Evidence/GetCounters', {
            method: 'POST',
            dataType: "json",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Accept": "application/json"
            },
        }).then(response => response.json())
            .then(data => {
                for (var i = 0; i < select_data.length; i++) {
                    var select_element = document.getElementById(select_data[i]);
                    if (select_element === null) {
                        return;
                    }
                    data[select_data[i]].map(element => {
                        var option = document.createElement("option");
                        option.text = element.item2;
                        option.value = element.item1;
                        select_element.add(option);
                    });
                    this.setState({ [select_data[i]]: select_element.options[0].value }, () => { this.checkSelectedOption(select_data[i]); });
                }

                //Pokud je nastaven questionaireID pak jde o upravu existujiciho dotazniku a nacte ulozena data, jinak jde o vlozeni noveho dotazniku
                if (this.props.questionaireID) {
                    this.fetchSavedData(select_data, text_data, date_data);
                }
            });;
    }

    fetchSavedData(select_data, text_data, date_data) {
        //Vratim vsechny ulozene data z databaze
        fetch('api/Evidence/GetSavedData', {
            method: 'POST',
            dataType: "json",
            body: JSON.stringify({
                "id": this.props.questionaireID
            }),
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Accept": "application/json"
            },
        }).then(response => response.json())
            .then(data => {
                //Nastavi hodnoty textovych polozek v state a nastavi jejich hodnoty v text input
                for (var i = 0; i < text_data.length; i++) 
                    this.setStateFromJson(data, text_data[i], text_data[i]);

                //Nastav vsechny date polozky do state
                for (var i = 0; i < date_data.length; i++) 
                    this.setDateStateFromJson(data, date_data[i], date_data[i]);

                //Pro ulozene options selectu najde jejich hodnoty a nastavi je jako vybrane
                for (var i = 0; i < select_data.length; i++) {
                    this.setSelectFromJson(data, select_data[i], select_data[i]);
                    this.checkSelectedOption(select_data[i]);
                }
            });
    }

    setStateFromJson(data, state, dataName) {
        //Nastavi textovou hodnotu do state
        if (data[dataName] != null) {
            this.setState({ [state]: data[dataName][0] }, () => { document.getElementById(state).value = data[dataName][0]; });
        }
    }

    setDateStateFromJson(data, state, dataName) {
        //Vytvori novy datum z dat a ulozi je do state 
        if (data[dataName] != null && data[dataName] != "") {
            if (data[dataName][0].includes(".")) {                              //Podle jestli je date v anglicke lokaci nebo ceske (18.6 vs 18/6)
                var split_date = data[dataName][0].split('.');
                var date = new Date(new Date().getFullYear(), split_date[1] - 1, split_date[0]);
            }
            else {
                var split_date = data[dataName][0].split('/');
                var date = new Date(new Date().getFullYear(), split_date[0] - 1, split_date[1]);
            }
            this.setState({ [state]: date });
        }
    }

    setSelectFromJson(data, state, dataName) {
        //Najde odpovidajici option a oznaci ho
        if (data[dataName] != null) {
            var select = document.getElementById(state);
            if (select === null) {
                return;
            }
            for (var i = 0; i < select.options.length; i++) {
                if (data[dataName][0] === select.options[i].value) {
                    this.setState({ [state]: select.options[i].value });
                    select.options[i].selected = true;
                    if (select.options[i].value !== "others") {
                        document.getElementById(state + "-div").style.display = "none";
                    }
                    break;
                }
            }
        }
    }

    checkSelectedOption(elName) {
        //Jestli je v selectu vybrana others option pak zviditelni text input pro zadani jine moznosti
        var el = document.getElementById(elName)
        var opt = el.options[el.selectedIndex].value;
        if (opt === 'others') {
            el = document.getElementById(elName + "-div");
            el.style.display = "block";
        }
    }

    render() {  
        return (
            <div>
                <div className="col-md-1">
                </div>
                <div className="ev-uk-form col-md-8 col-md-offset-1">
                    <span class="glyphicon glyphicon-remove-sign tv-zprav-details-close" aria-hidden="true" onClick={() => { this.props.callbackFromParent() }}></span>
                    <div className="row ev-uk-header">
                        <h2 className="col-md-offset-4 ev-uk-question-header">Evidence ukazatelů</h2>
                    </div>
                    <div id="tel" className="ev-uk-option">
                        <div className="row">
                            <label className="col-md-4" for="listTyp">1) Oblast<i class="ev-uk-gl glyphicon glyphicon-question-sign" title="Které oblasti se ukazatel týká - studijní, personální, ekonomické, zahraniční, vědecké, výzkumné, vzdělávací"></i></label>
                            <select id="oblast" className="col-md-3 ev-uk-sel" name="oblast-div" onChange={(event) => this.setStateList(event, "jina_oblast_uk")} value={this.state.oblast}>
                                <option value="others">Jiná oblast</option>
                            </select>
                        </div>
                        <div className="row ev-uk-div-sh-top" id='oblast-div'>
                            <br />
                            <label className="col-md-4" for="listTyp">Zadejte jinou oblast</label>
                            <textarea id="jina_oblast_uk" onChange={this.setStateTxt} maxLength="256" className="ev-uk-txt-ar-h col-md-3 ev-uk-txt-ar" rows="1" />
                        </div>
                    </div>

                    <div id="tel" class="ev-uk-option ev-uk-question">
                        <div className="row">
                            <label className="col-md-4" for="listTyp">2) Číslo ukazatele<i class="ev-uk-gl glyphicon glyphicon-question-sign" title="Pod kterým pořadovým číslem je ukazatel veden v seznamu všech sledovaných ukazatelů v rámci KA7"></i></label>
                            <select id="ukazatel" className="col-md-3 ev-uk-sel" name="ukazatel-div" onChange={(event) => this.setStateList(event, "jine_cis_uk")} value={this.state.ukazatel}>
                                <option value="others">Jiné číslo</option>
                            </select>
                        </div>
                        <div className="row ev-uk-div-sh-top" id='ukazatel-div'>
                            <br />
                            <label className="col-md-4" for="listTyp">Zadejte jiný ukazatel</label>
                            <textarea id="jine_cis_uk" onChange={this.setStateTxt} maxLength="256" className="ev-uk-txt-ar-h col-md-3 ev-uk-txt-ar" rows="1" />
                        </div>
                    </div>

                    <div id="tel" className="ev-uk-question">
                        <div className="row">
                            <label className="col-md-4" for="listTyp">3) Definice ukazatele<i class="ev-uk-gl glyphicon glyphicon-question-sign" title="Jak je ukazatel definován - ať už ze strany MŠMT či jiných nadřízených orgánů anebo interně"></i></label>
                            <textarea id="definice_uk" onChange={this.setStateTxt} maxLength="256" className="ev-uk-txt-ar-h col-md-7 ev-uk-txt-ar" rows="1" />
                        </div>
                    </div>
                    <div id="tel" className="ev-uk-question">
                        <div className="row">
                            <label for="listTyp" className="col-md-4">4) Odpovědné pracoviště<i class="ev-uk-gl glyphicon glyphicon-question-sign" title="Na kterém pracovišti se údaje sbírají, počítají a vykazují"></i></label>
                            <textarea id="prac_nazev" onChange={this.setStateTxt} maxLength="256" className="ev-uk-txt-ar-h col-md-7 ev-uk-txt-ar" rows="1" />
                        </div>
                    </div>
                    <div id="tel" className="ev-uk-question">
                        <div className="row">
                            <label for="listTyp" className="col-md-4">5) Odpovědný pracovník<i class="ev-uk-gl glyphicon glyphicon-question-sign" title="Který pracovník údaje sbírá, počítá a vykazuje"></i></label>
                            <textarea id="odpovedna_osoba" onChange={this.setStateTxt} maxLength="256" className="ev-uk-txt-ar-h col-md-7 ev-uk-txt-ar" rows="1" />
                        </div>
                    </div>

                    <div id="tel" className="ev-uk-option ev-uk-question">
                        <div className="row">
                            <label className="col-md-4" for="listTyp">6) Zdroj dat<i class="ev-uk-gl glyphicon glyphicon-question-sign" title="Kde se nacházejí zdrojová data pro výpočet ukazatele"></i></label>
                            <select id="zdroj" className="col-md-3 ev-uk-sel" name="zdroj-div" onChange={(event) => this.setStateList(event, "jiny_zdroj_uk")} value={this.state.zdroj}>
                                <option value="others">Jiný zdroj</option>
                            </select>
                        </div> 
                        <div className="row ev-uk-div-sh-top" id='zdroj-div'>
                            <br />
                            <label className="col-md-4" for="listTyp">Zadejte jiný zdroj</label>
                            <textarea id="jiny_zdroj_uk" onChange={this.setStateTxt} maxLength="256" className="ev-uk-txt-ar-h col-md-3 ev-uk-txt-ar" rows="1" />
                        </div>
                    </div>
                    <div id="tel" className="ev-uk-question">
                        <div className="row">
                            <label for="listTyp" className="col-md-4">7) Garant<i class="ev-uk-gl glyphicon glyphicon-question-sign" title="Jméno příjmení + pozn."></i></label>
                            <textarea maxLength="256" id="garant" onChange={this.setStateTxt} className="ev-uk-txt-ar-h col-md-7 ev-uk-txt-ar" rows="1" />
                        </div>
                    </div>
                    <div id="tel" className="ev-uk-question">
                        <div className="row">
                            <label for="listTyp" className="col-md-4">8) Vzorec výpočtu<i class="ev-uk-gl glyphicon glyphicon-question-sign" title="Jak se ukazatel počítá"></i></label>
                            <textarea maxLength="256" id="vzorec" onChange={this.setStateTxt} className="ev-uk-txt-ar-h col-md-7 ev-uk-txt-ar" rows="1" />
                        </div>
                    </div>
                    <div className="ev-uk-question">
                        <div className="row">
                            <label htmlFor="yearStart" className="col-md-4">9) K jakému datu se sbírají podklady</label>
                            <span className="col-md-1">Od:</span>
                            <DatePicker className="ev-uk-date col-md-2"
                                id="podklady-picker-from"
                                format='dd-MM'
                                minDate={new Date(this.state.year, 0, 1)}
                                maxDate={new Date(this.state.year, 11, 31)}
                                minDetail="month"
                                locale="cs-CZ"
                                onChange={(e) => this.changeDate('datePodklady_from', e)}
                                value={this.state.datePodklady_from}
                            />
                            <span className="col-md-1">Do:</span>
                            <DatePicker className="ev-uk-date col-md-2"
                                id="podklady-picker-to"
                                format='dd-MM'
                                minDate={new Date(this.state.year, 0, 1)}  
                                maxDate={new Date(this.state.year, 11, 31)}
                                minDetail="month"
                                locale="cs-CZ"
                                onChange={(e) => this.changeDate('datePodklady_to', e)}
                                value={this.state.datePodklady_to}
                            />
                        </div>
                    </div>
                    <div className="ev-uk-question">
                        <div className="row">
                            <label htmlFor="yearStart" className="col-md-4">10) K jakému datu se provádí výpočet</label>
                            <span className="col-md-1">Od:</span>
                            <DatePicker className="ev-uk-date col-md-2"
                                id="podklady-picker"
                                format='dd-MM'
                                minDate={new Date(this.state.year, 0, 1)}
                                maxDate={new Date(this.state.year, 11, 31)}
                                minDetail="month"
                                maxDetail="month"
                                locale="cs-CZ"
                                onChange={(e) => this.changeDate('dateVypocet_from', e)}
                                value={this.state.dateVypocet_from}
                            />
                            <span className="col-md-1">Do:</span>
                            <DatePicker className="ev-uk-date col-md-2"
                                id="podklady-picker"
                                format='dd-MM'
                                minDate={new Date(this.state.year, 0, 1)}
                                maxDate={new Date(this.state.year, 11, 31)}
                                minDetail="month"
                                locale="cs-CZ"
                                onChange={(e) => this.changeDate('dateVypocet_to', e)}
                                value={this.state.dateVypocet_to}
                            />
                        </div>
                    </div>
                    <div className="ev-uk-question">
                        <div className="row">
                            <label htmlFor="yearStart" className="col-md-4">11) K jakému datu se vykazuje</label>
                            <span className="col-md-1">Od:</span>
                            <DatePicker className="ev-uk-date col-md-2"
                                id="podklady-picker"
                                format='dd-MM'
                                minDate={new Date(this.state.year, 0, 1)}
                                maxDate={new Date(this.state.year, 11, 31)}
                                minDetail="month"
                                maxDetail="month"
                                locale="cs-CZ"
                                onChange={(e) => this.changeDate('dateVykaz_from', e)}
                                value={this.state.dateVykaz_from}
                            />
                            <span className="col-md-1">Do:</span>
                            <DatePicker className="ev-uk-date col-md-2"
                                id="podklady-picker"
                                format='dd-MM'
                                minDate={new Date(this.state.year, 0, 1)}
                                maxDate={new Date(this.state.year, 11, 31)}
                                minDetail="month"
                                locale="cs-CZ"
                                onChange={(e) => this.changeDate('dateVykaz_to', e)}
                                value={this.state.dateVykaz_to}
                            />
                        </div>
                    </div>
                    <div className="ev-uk-question">
                        <div className="row">
                            <label htmlFor="yearStart" className="col-md-4">12) K jakému datu se provádí jiné úkoly</label>
                            <span className="col-md-1">Od:</span>
                            <DatePicker className="ev-uk-date col-md-2"
                                id="podklady-picker"
                                format='dd-MM'
                                minDate={new Date(this.state.year, 0, 1)}
                                maxDate={new Date(this.state.year, 11, 31)}
                                minDetail="month"
                                maxDetail="month"
                                locale="cs-CZ"
                                onChange={(e) => this.changeDate('dateUkony_from', e)}
                                value={this.state.dateUkony_from}
                            />
                            <span className="col-md-1">Do:</span>
                            <DatePicker className="ev-uk-date col-md-2"
                                id="podklady-picker"
                                format='dd-MM'
                                minDate={new Date(this.state.year, 0, 1)}
                                maxDate={new Date(this.state.year, 11, 31)}
                                minDetail="month"
                                locale="cs-CZ"
                                onChange={(e) => this.changeDate('dateUkony_to', e)}
                                value={this.state.dateUkony_to}
                            />
                        </div>
                    </div>
                    <div id="tel" className="ev-uk-question">
                        <div className="row">
                            <label className="col-md-4" for="listTyp">13) Popište jiné úkony</label>
                            <textarea id="jine_ukony" onChange={this.setStateTxt} maxLength="512" className="ev-uk-txt-ar-h col-md-7 ev-uk-txt-ar" rows="1" />
                        </div>
                    </div>
                    <div id="tel" className="ev-uk-question">
                        <div className="row">
                            <label className="col-md-4" for="listTyp">14) Pro koho se vykazuje<i class="ev-uk-gl glyphicon glyphicon-question-sign" title="Kterým orgánům státní správy jsou data určena"></i></label>
                            <textarea id="prijemce_vykazu" onChange={this.setStateTxt} maxLength="256" className="ev-uk-txt-ar-h col-md-7 ev-uk-txt-ar" rows="1" />
                        </div>
                    </div>
                    <div id="tel" className="ev-uk-question">
                        <div className="row">
                            <label className="col-md-4" for="listTyp">15) Forma výkazu<i class="ev-uk-gl glyphicon glyphicon-question-sign" title="V jakém formátu je výkaz - popis"></i></label>
                            <textarea id="forma_vykazu" onChange={this.setStateTxt} maxLength="256" className="ev-uk-txt-ar-h col-md-7 ev-uk-txt-ar" rows="1" />
                        </div>
                    </div>
                    <div id="tel" className="ev-uk-question ">
                        <div className="row">
                            <label for="listTyp" className="col-md-4">16) Okruh osob oprávněných nahlížet<i class="ev-uk-gl glyphicon glyphicon-question-sign" title="Okruh osob s uživatelským oprávněním nahlížet údaje k tomuto ukazateli v naší aplikaci"></i></label>
                            <textarea id="opravnene_osoby" onChange={this.setStateTxt} maxLength="256" className="ev-uk-txt-ar-h col-md-7 ev-uk-txt-ar" rows="1" />
                        </div>
                    </div>
                    <div id="tel" className="ev-uk-question">
                        <div className="row">
                            <label className="col-md-4" for="listTyp">17) Doporučení filtry v zobrazení ukazatele<i class="ev-uk-gl glyphicon glyphicon-question-sign" title="Které pohledy na na jednotlivé údaje tohoto ukazatele jsou nejčastější, nejdůležitější a/nebo nejzajímavější?"></i></label>
                            <textarea id="pohledy" onChange={this.setStateTxt} maxLength="256" className="ev-uk-txt-ar-h col-md-7 ev-uk-txt-ar" rows="1" />
                        </div>
                    </div>

                    <div id="tel" className="ev-uk-question">
                        <div className="row">
                            <label className="col-md-4" for="listTyp">18) Poznámky<i class="ev-uk-gl glyphicon glyphicon-question-sign" title="Cokoliv Vás napadne zmínit v souvislosti se sledováním tohoto ukazatele, sběrem dat k jeho výpočtu, k výpočtu samotnému, ke způsobu a formátu vykazování"></i></label>
                            <textarea id="poznamky" onChange={this.setStateTxt} className="col-md-7 ev-uk-txt-ar" rows="4"  />
                        </div>
                    </div>

                    <div id="tel" className="ev-uk-btn-div">
                        <div className="row">
                            <p className="col-md-4" />
                            <Button className="ev-uk-btn col-md-3 offset-md-4" active onClick={this.saveData}>ULOŽIT</Button>
                        </div>
                    </div>
                    <div id="tel" className="ev-uk-btn-div">
                        <div className="row">
                            <p className="col-md-4" />
                            <div id="ev-uk-resp" className="col-md-3 " />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
import React, { Component } from 'react';
import Login from './components/Login';
import Home from './components/Home';
import Logout from './components/Logout';
import { Route, BrowserRouter as Router } from 'react-router-dom'
import { Layout } from './components/Layout';
import { UspesnostStudia } from './components/OstatniUkazatele/UspesnostStudia';
import { PrijimaciRizeni } from './components/OstatniUkazatele/PrijimaciRizeni';
import { EditaceUkazatelu } from './components/EvidenceUkazatelu/EditaceUkazatelu';
import { EvidenceUkazatelu } from './components/EvidenceUkazatelu/EvidenceUkazatelu'
import { NeuspesnostSoucasti } from './components/ManazerskaData/NeuspesnostSoucasti'
import { ProgramyAStudia } from './components/ManazerskaData/ProgramyAStudia'
import { ManazerskaData } from './components/ManazerskaData/ManazerskaData'
import { MezinarodniSpoluprace } from './components/ManazerskaData/MezinarodniSpoluprace'
import { MobilitaAbsolventu } from './components/ManazerskaData/MobilitaAbsolventu'
import { MobilityPodleZemi } from './components/ManazerskaData/MobilityPodleZemi'
import { ZamestnanciCelkem } from './components/ManazerskaData/ZamestnanciCelkem'
import { StrukturaZamestnancu } from './components/ManazerskaData/StrukturaZamestnancu'
import { Stipendia } from './components/ManazerskaData/Stipendia'
import { PoctyPracovniku } from './components/ManazerskaData/PoctyPracovniku'
import { ZamestnanciCizinci } from './components/ManazerskaData/ZamestnanciCizinci'
import { Podrobnosti } from './components/Podrobnosti'
import { ZajemStudium } from './components/ManazerskaData/ZajemStudiumVS';


const RequireAuth = (Component, Child, title) => {

    return class App extends Component {

        componentWillMount() {
            const getToken = localStorage.getItem('token');
            if (!getToken) {
                this.props.history.replace({ pathname: '/' });
            }
        }
        render() {
            var child = null;

            if (Child !== null) {
                child = <Child {...this.props} />
            }
            return (
                <Layout title={title} component={<Component {...this.props} >{child}</ Component>}></Layout>
            );
        }
    }
}

export { RequireAuth }

export default class App extends Component {
  displayName = App.name
  
  render() {
    return (        
        <Router>
            <div>
                <Route exact path='/' component={Login} />
                <Route exact path={'/home'} component={RequireAuth(Home, null, "Eviden\u010dn\u00ed, agrega\u010dn\u00ed a prezenta\u010dn\u00ed syst\u00E9m pro zpracov\u00E1n\u00ed dat relevantn\u00edch pro hodnocen\u00ed a zaji\u0161\u0165ov\u00E1n\u00ed kvality \u010dninnost\u00ed univerzity a pro strategick\u00E9 \u0159\u00edzen\u00ed univerzity.")}/>    
                <Route exact path={'/editace-ukazatelu'} component={RequireAuth(EditaceUkazatelu, EvidenceUkazatelu, 'Evidence ukazatel\u016f')} />
                <Route exact path={'/napoveda'} component={RequireAuth(Podrobnosti, null, "Eviden\u010dn\u00ed, agrega\u010dn\u00ed a prezenta\u010dn\u00ed syst\u00E9m pro zpracov\u00E1n\u00ed dat relevantn\u00edch pro hodnocen\u00ed a zaji\u0161\u0165ov\u00E1n\u00ed kvality \u010dninnost\u00ed univerzity a pro strategick\u00E9 \u0159\u00edzen\u00ed univerzity.")} />


                <Route exact path={'/manazerska_data/prijimaci_rizeni'} component={RequireAuth(ManazerskaData, PrijimaciRizeni, "Souhrn v\u00fdsledk\u016f p\u0159ij\u00edmac\u00edho \u0159\u00edzen\u00ed")}/>
                <Route exact path={'/manazerska_data/uspesnost_studia'} component={RequireAuth(ManazerskaData, UspesnostStudia, "\u00DAsp\u011b\u0161nost studia")}/>
                <Route exact path={'/manazerska_data/akreditovane_programy'} component={RequireAuth(ManazerskaData, ProgramyAStudia, "Po\u010dty akreditovan\u00fdch studijn\u00edch program\u016f")} />
                <Route exact path={'/manazerska_data/akreditovane_programy_cj'} component={RequireAuth(ManazerskaData, ProgramyAStudia, "Po\u010dty akreditovan\u00fdch studijn\u00edch program\u016f vyu\u010dovan\u00fdch v ciz\u00edm jazyce")} />
                <Route exact path={'/manazerska_data/pocty_studii'} component={RequireAuth(ManazerskaData, ProgramyAStudia, "Po\u010dty studi\u00ed dle typu a formy studia jen cizinci")} />
                <Route exact path={'/manazerska_data/pocty_studii_samoplatci'} component={RequireAuth(ManazerskaData, ProgramyAStudia, "Po\u010dty studi\u00ed dle typu a formy studia jen samopl\u00e1tci")} />
                <Route exact path={'/manazerska_data/absolventi_bez_samoplatci'} component={RequireAuth(ManazerskaData, ProgramyAStudia, "Po\u010dty absolvent\u016f bez samopl\u00e1tc\u016f")} />
                <Route exact path={'/manazerska_data/neuspesnost_soucast'} component={RequireAuth(ManazerskaData, NeuspesnostSoucasti, "Po\u010dty ne\u00FAsp\u011b\u0161\u00fdch studi\u00ed dle sou\u010d\u00e1sti, typu a formy studia")} />
                <Route exact path={'/manazerska_data/zamestnanci_celkem'} component={RequireAuth(ManazerskaData, ZamestnanciCelkem, "Akademi\u010dt\u00ed a v\u011bde\u010dt\u00ed pracovn\u00edci a ostatn\u00ed zam\u011bstnanci celkem")} />
                <Route exact path={'/manazerska_data/struktura_zamestnancu'} component={RequireAuth(ManazerskaData, StrukturaZamestnancu, "V\u011bkov\u00e1 struktura zam\u011bstnanc\u016f" )} />
                <Route exact path={'/manazerska_data/mezinarodni_spoluprace'} component={RequireAuth(ManazerskaData, MezinarodniSpoluprace, "Zapojen\u00ed vysok\u00E9 \u0161koly do program\u016f mezin\u00E1rodn\u00ed spolupr\u00E1ce")} />
                <Route exact path={'/manazerska_data/mobilita'} component={RequireAuth(ManazerskaData, MobilityPodleZemi, "Mobilita student\u016f, akademick\u00fdch a ostatn\u00edch pracovn\u00edk\u016f podle zem\u00ed")} />
                <Route exact path={'/manazerska_data/mobilita_absolventu'} component={RequireAuth(ManazerskaData, MobilitaAbsolventu, "Mobilita absolvent\u016f (po\u010dty a pod\u00edly absolvovank\u00fdch studi\u00ed)")} />
                <Route exact path={'/manazerska_data/stipendia'} component={RequireAuth(ManazerskaData, Stipendia, "Stipendia student\u016fm podle \u00DA\u010delu stipendia")} />
                <Route exact path={'/manazerska_data/pocty_pracovniku'} component={RequireAuth(ManazerskaData, PoctyPracovniku, "Po\u010dty pracovn\u00edk\u016f podle rozsahu pracovn\u00edch \u00FAvazk\u016f a nejvy\u0161\u0161\u00ed dosa\u017Een\u00E9 kvalifikace")} />
                <Route exact path={'/manazerska_data/pracovnici_cizinci'} component={RequireAuth(ManazerskaData, ZamestnanciCizinci, "Akademi\u010Dt\u00ED a v\u011Bde\u010Dt\u00ED pracovn\u00EDci s ciz\u00EDm st\u00E1tn\u00EDm ob\u010Danstv\u00EDm")} />
                <Route exact path={'/manazerska_data/zajem_studium'} component={RequireAuth(ManazerskaData, ZajemStudium, "Z\u00e1jem o studium na vysok\u00E9 \u0161kole")} />

                <Route exact path={'/manazerska_data'} component={RequireAuth(ManazerskaData, null, "Eviden\u010dn\u00ed, agrega\u010dn\u00ed a prezenta\u010dn\u00ed syst\u00E9m pro zpracov\u00E1n\u00ed dat relevantn\u00edch pro hodnocen\u00ed a zaji\u0161\u0165ov\u00E1n\u00ed kvality \u010dninnost\u00ed univerzity a pro strategick\u00E9 \u0159\u00edzen\u00ed univerzity.")} />

                <Route exact path='/logout' component={Logout} />
            </div>
        </Router>
    );
  }
}

    
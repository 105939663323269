import React, { Component } from 'react';
import '../../css/details.css';


export class Details extends Component {
    //Komponenta zobrazi podrobnosti o ukazateli.
    constructor(props) {
        super(props);
        this.state = {
            item: {
                child_data: {}
            }
        }
        this.download_tamplate = this.download_tamplate.bind(this);
    }

    componentDidMount() {
        var selected_item = JSON.parse(this.props.selected_item);
        this.setState({
            item: selected_item,
            filename: selected_item["number"] + "_sablona.xlsx"
        });
    }

    download_tamplate() {
        //Vrati excell soubor v novem okne
        var filename = this.state.filename;
        fetch('api/ExcellExport/ReturnExcelFile', {
            method: 'POST',
            dataType: "json",
            body: filename,
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'), // Add the authentication header
                "Accept": "application/json"
            },
        }).then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
    }

    render() {
        return (
            <div className="tv-zprav-details-main-div">
                <div className="col-md-8 col-md-offset-2 coll-sm-12 tv-detail-form">
                    <span class="glyphicon glyphicon-remove-sign tv-zprav-details-close" aria-hidden="true" onClick={(event) => this.props.close(event, "details")}></span>
                    <h3 className="tv-detail-header">{this.state.item["title"]}</h3>
                    <br />
                    <p><span>Stáhnout šablonu:</span><span onClick={this.download_tamplate} className="tv-detail-link">{this.state.filename}</span></p>
                </div>
            </div>


        );
    }
}

import React, { Component } from 'react';
import { ManazerskaData } from './ManazerskaData';
import { ManazerskaDataMenu } from './ManazerskaDataMenu';


export class ZajemStudium extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    fetchData(year) {
        fetch(this.props.child_data["data_location"], {
            method: 'POST',
            dataType: "json",
            body: JSON.stringify({ "year": year }),
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'), // Add the authentication header
                "Accept": "application/json"
            },
        }).then(response => response.json())
            .then(data => {
                this.setState({ "data": data });
            });;
    }

    render() {
        return (
            <ManazerskaData title="Zájem o studium na vysoké škole">
                <div>
                    <ManazerskaDataMenu fetchData={this.fetchData.bind(this)} headers={this.props.header} data={this.state.data} child_data={this.props.child_data} />
                    <div className="ac-table-div">
                        <table className='ap-table-auto-position col-sm-12'>
                            <div className="ap-head-div">
                                <thead className="ap-table-header-scrollbar">
                                    <tr>
                                        <th rowspan="2" colspan="4" scope="colgroup">Vysoká škola (název)</th>
                                        <th rowspan="1" colspan="4" scope="colgroup">Bakalářské</th>
                                        <th rowspan="1" colspan="4" scope="colgroup">Magisterské</th>
                                        <th rowspan="1" colspan="4" scope="colgroup">Navazující</th>                 
                                        <th rowspan="1" colspan="4" scope="colgroup">Doktorské</th>
                                    </tr>
                                    <tr>
                                        <th colspan="1">Počet uchazečů (fyzické osoby)</th>
                                        <th colspan="1">Počet přihlášek</th>
                                        <th colspan="1">Počet přijetí</th>
                                        <th colspan="1">Počet zápisů ke studiu</th>
                                        <th colspan="1">Počet uchazečů (fyzické osoby)</th>
                                        <th colspan="1">Počet přihlášek</th>
                                        <th colspan="1">Počet přijetí</th>
                                        <th colspan="1">Počet zápisů ke studiu</th>
                                        <th colspan="1">Počet uchazečů (fyzické osoby)</th>
                                        <th colspan="1">Počet přihlášek</th>
                                        <th colspan="1">Počet přijetí</th>
                                        <th colspan="1">Počet zápisů ke studiu</th>
                                        <th colspan="1">Počet uchazečů (fyzické osoby)</th>
                                        <th colspan="1">Počet přihlášek</th>
                                        <th colspan="1">Počet přijetí</th>
                                        <th colspan="1">Počet zápisů ke studiu</th>
                                    </tr>
                                </thead>
                                <div className="ap-table-pad"></div>
                            </div>
                            <tbody className="ac-tbody" id="ap-uk-tab">
                                {
                                    this.state.data.map((row) => (
                                        <tr className="pr_tr ed-uk-tr">
                                            <td colspan="4" className="pr_td">{row[0]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[1]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[2]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[3]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[4]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[5]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[6]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[7]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[8]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[9]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[10]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[11]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[12]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[13]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[14]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[15]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[16]}</td>
                                            <td colspan="1" className="pr_td ps-num-tab-col">{row[17]}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </ManazerskaData>
        );
    }
}

//Konfiguracni soubor s daty o ukazatelich a jejich exortu

export const menu_config = 
{    
    "AKREDITOVANÉ PROGRAMY": [
        { "number": 2.1, "name": "Akreditované studijní programy", "path": "/manazerska_data/akreditovane_programy", "data_location": "api/AkreditovaneProgramy/AkreditovaneProgramy", "title": "Tab. 2.1: Akreditované studijní programy (počty)", "columns_length": [47, 6, 7.57, 6.14, 7.86, 6.71, 8, 6.29, 8.43, 8.43, 8.43] },
        { "number": 2.2, "name": "Studijní programy v cizím jazyce", "path": "/manazerska_data/akreditovane_programy_cj", "data_location": "api/AkreditovaneProgramy/AkreditovaneProgramyCJ", "title": "Tab. 2.2: Studijní programy v cizím jazyce (počty)", "columns_length": [47.14, 6, 7.57, 6.14, 7.86, 6.71, 8, 6.29, 8.43, 8.43, 8.43] }
    ],
    "POČTY STUDIÍ": [
        { "number": 3.1, "name": "Studenti v akreditovaných studijních programech", "path": "/manazerska_data/pocty_studii", "data_location": "api/AkreditovaneProgramy/PoctyStudii", "title": "Tab. 3.1: Studenti v akreditovaných studijních programech(počty studií)", "columns_length": [47.14, 6, 7.57, 6.14, 7.86, 6.71, 8, 6.29, 8.43, 8.43, 8.43] },
        { "number": 3.2, "name": "Studenti - samoplátci", "path": "/manazerska_data/pocty_studii_samoplatci", "data_location": "api/AkreditovaneProgramy/StudentiSamoplatci", "title": "Tab. 3.2: Studenti - samoplátci** (počty studií)", "columns_length": [47.14, 6, 7.57, 6.14, 7.86, 6.71, 8, 6.29, 8.43, 8.43, 8.43] }
    ],
    "NEÚSPĚŠNOST": [
        { "number": 3.3, "name": "Studijní neúspěšnost 1. ročníku", "path": "/manazerska_data/neuspesnost_soucast", "data_location": "api/NeuspesnostStipendia/GetStudiesRate", "title": "Tab. 3.3: Studijní neúspěšnost* 1. ročníku** studia (v %)", "columns_length": [22, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43] } 
    ],
    "STIPENDIA": [
        { "number": 3.4, "name": "Stipendia studentům podle účelu stipendia", "path": "/manazerska_data/stipendia", "data_location": "api/NeuspesnostStipendia/GetStudentGrants", "title": "Tab. 3.4: Stipendia* studentům podle účelu stipendia", "columns_length": [54.14, 12.71, 21.71] } 
    ],
    "ABSOLVENTI": [
        { "number": 4.1, "name": "Absolventi akreditovaných studijních programů", "path": "/manazerska_data/absolventi_bez_samoplatci", "data_location": "api/AkreditovaneProgramy/AbsolventiBezSamoplatci", "title": "Tab. 4.1: Absolventi akreditovaných studijních programů (počty absolvovaných studií)", "columns_length": [47.14, 6, 7.57, 6.14, 7.86, 6.71, 8, 6.29, 8.43, 8.43, 8.43] } 
    ],
    "ZÁJEM O STUDIUM": [
        { "number": 5.1, "name": "Zájem o studium na vysoké škole", "path": "/manazerska_data/zajem_studium", "data_location": "api/ZajemStudium/ZajemoStudium", "title": "5.1: Zájem o studium na vysoké škole", "columns_length": [22.29, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43] }
    ],
    "ZAMĚSTNANCI": [
        { "number": 6.1, "name": "Zaměstnanci celkem", "path": "/manazerska_data/zamestnanci_celkem", "data_location": "api/Zamestnanci/ZamestnanciCelkem", "title": "Tab. 6.1: Akademičtí a vědečtí pracovníci a ostatní zaměstnanci celkem (průměrné přepočtené počty*)", "columns_length": [29.29, 9.86, 9.86, 9.86, 9.86, 9.86, 9.86, 9.86, 9.86, 10.43, 9.86, 9.86, 9.86, 8.43] },
        { "number": 6.2, "name": "Věková struktura zaměstnanců", "path": "/manazerska_data/struktura_zamestnancu", "data_location": "api/Zamestnanci/VekovaStrukturaZamestnancu", "title": "Tab. 6.2: Věková struktura akademických, vědeckých a ostatních pracovníků (počty fyzických osob*)", "columns_length": [22.29, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43, 8.43], "single_table": false },
        { "number": 6.3, "name": "Počty pracovníků podle rozsahu pracovních úvazků a nejvyšší dosažené kvalifikace", "path": "/manazerska_data/pocty_pracovniku", "data_location": "api/Zamestnanci/ZamestnanciPodleKvalifikace", "title": "Tab. 6.3: Počty akademických a vědeckých pracovníků podle rozsahu pracovních úvazků a nejvyšší dosažené kvalifikace (počty fyzických osob dle rozsahu úvazků)", "columns_length": [29.29, 10.14, 10.14, 10.14, 10.14, 10.14, 10.14, 10.14, 10.14, 10.14, 10.14, 10.14, 10.14], "single_table": false },
        { "number": 6.5, "name": "Akademičtí a vědečtí pracovníci s cizím státním občanstvím", "path": "/manazerska_data/pracovnici_cizinci", "data_location": "api/Zamestnanci/ZamestnanciCizinci", "title": "Tab. 6.5: Akademičtí a vědečtí pracovníci s cizím státním občanstvím (průměrné přepočtené počty******)", "columns_length": [47.57, 11.43, 11.43, 11.43, 11.43, 11.43, 11.43, 11.43, 11.43, 11.43, 11.43], "single_table": false } 
    ],
    "MEZINÁRODNÍ SPOLUPRÁCE": [
        { "number": 7.1, "name": "Zapojení vysoké školy do programů mezinárodní spolupráce", "path": "/manazerska_data/mezinarodni_spoluprace", "data_location": "api/SpolupraceMobility/MezinarodniSpoluprace", "title": "Tab. 7.1: Zapojení vysoké školy do programů mezinárodní spolupráce (bez ohledu na zdroj financování)", "columns_length": [22, 9.86, 10.71, 11.29, 23.29] } 
    ],
    "Mobility": [
        { "number": 7.2, "name": "Mobilita studentů, akademických a ostatních pracovníků podle zemí", "path": "/manazerska_data/mobilita", "data_location": "api/SpolupraceMobility/MobilityStudentu", "title": "Tab. 7.2: Mobilita studentů, akademických a ostatních pracovníků podle zemí***** (bez ohledu na zdroj financování) (vysoká škola bez dalšího zásahu pouze vyplní tabulku příslušnými hodnotami)", "columns_length": [51.14, 12.29, 12.29, 10.57, 14.71, 14.71, 14.71, 14.71, 8.43] },
        { "number": 7.3, "name": "Mobilita absolventů", "path": "/manazerska_data/mobilita_absolventu", "data_location": "api/SpolupraceMobility/MobilitaAbsolventu", "title": "Tab. 7.3: Mobilita absolventů** (počty a podíly absolvovaných studií)", "columns_length": [32, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12] },
    ],
    "OSTATNÍ UKAZATELE": [          //Ostatní které nejsou součásti výroční zprávy
        { "name": "Přijímací řízení", "path": "/manazerska_data/prijimaci_rizeni", "data_location": "api/PrijimaciRizeni/PrijimaciRizeni", "title": "Souhrn výsledků přijímacího řízení", "columns_length": [12, 6, 6, 6, 12, 8, 6, 6, 6, 6, 6] },
        { "name": "Úspěšnost studia", "path": "/manazerska_data/uspesnost_studia", "data_location": "api/UspesnostStudia/UspesnostStudia", "title": "Úspěšnost studia", "columns_length": [8, 16, 16, 16, 16, 16] }
    ],
}

const programy_studia = {
    n_columns: 11,                                  //Pocet sloupcu tabulky
    row_heights: [38.25, 13.5],                     //Vyska radku zahlavi
    table_headers:
        [
            [
                { "text": "Slezská univerzita v Opavě", "length": 1 },
                { "text": "", "length": 1 },
                { "text": "Bakalářské studium", "length": 2 },
                { "text": "Magisterské studium", "length": 2 },
                { "text": "Navazující magisterské studium", "length": 2 },
                { "text": "Doktorské studium", "length": 2 },
                { "text": "CELKEM", "length": 1 }
            ],
            [
                { "text": "", "length": 1 },
                { "text": "", "length": 1 },
                { "text": "P", "length": 1 },
                { "text": "K/D", "length": 1 },
                { "text": "P", "length": 1 },
                { "text": "K/D", "length": 1 },
                { "text": "P", "length": 1 },
                { "text": "K/D", "length": 1 },
                { "text": "P", "length": 1 },
                { "text": "K/D", "length": 1 },
                { "text": "", "length": 1 }
            ]
        ]
}

export const headers_config =
{
    prijimaci_rizeni: {
        n_columns: 11,
        row_heights: [15],
        table_headers:
            [
                [
                    { "text": "Program", "length": 1 },
                    { "text": "Kolo", "length": 1 },
                    { "text": "Typ", "length": 1 },
                    { "text": "Forma", "length": 1 },
                    { "text": "Obor", "length": 1 },
                    { "text": "Všeobecné", "length": 1 },
                    { "text": "Zapsaní", "length": 1 },
                    { "text": "Přijatí", "length": 1 },
                    { "text": "Nepřijatí", "length": 1 },
                    { "text": "Nedostavili", "length": 1 },
                    { "text": "Celkem", "length": 1 },
                ]
            ]
    },
    uspesnost_studia: {
        n_columns: 6,
        row_heights: [15],
        table_headers: 
            [
                [
                    { "text": "Rok", "length": 1 },
                    { "text": "Počet prváků", "length": 1 },
                    { "text": "Úspěšných prváků", "length": 1 },
                    { "text": "Počet absolventů", "length": 1 },
                    { "text": "Počet studií", "length": 1 },
                    { "text": "Neukončená studia", "length": 1 },
                ]
            ]
    },
    2.1: programy_studia,
    2.2: programy_studia,
    3.1: programy_studia,
    3.2: programy_studia,
    3.3: {
        n_columns: 14,
        row_heights: [15, 15],
        table_headers:
            [
                [
                    { "text": "Vysoká škola (název)", "length": 1 },
                    { "text": "Bakalářské studium", "length": 3 },
                    { "text": "Magisterské studium", "length": 3 },
                    { "text": "Navazující magisterské studium", "length": 3 },
                    { "text": "Doktorské studium", "length": 3 },
                    { "text": "CELKEM", "length": 1 }
                ],
                [
                    { "text": "", "length": 1 },
                    { "text": "P", "length": 1 },
                    { "text": "K/D", "length": 1 },
                    { "text": "CELKEM", "length": 1 },
                    { "text": "P", "length": 1 },
                    { "text": "K/D", "length": 1 },
                    { "text": "CELKEM", "length": 1 },
                    { "text": "P", "length": 1 },
                    { "text": "K/D", "length": 1 },
                    { "text": "CELKEM", "length": 1 },
                    { "text": "P", "length": 1 },
                    { "text": "K/D", "length": 1 },
                    { "text": "CELKEM", "length": 1 },
                    { "text": "", "length": 1 }
                ]
            ]
    },
    3.4: {
        n_columns: 3,
        row_heights: [40, 15],
        table_headers: [
            [
                { "text": "Vysoká škola (název)", "length": 1 },
                { "text": "", "length": 1 },
                { "text": "", "length": 1 }
            ],
            [
                { "text": "Účel stipendia", "length": 1 },
                { "text": "Počty studentů", "length": 1 },
                { "text": "Průměrná výše stipendia**", "length": 1 }
            ]
        ]
    },
    4.1: programy_studia,
    5.1: {
        n_columns: 18,                                  //Pocet sloupcu tabulky
        row_heights: [40, 15],                     //Vyska radku zahlavi
        table_headers:
            [
                [
                    { "text": "Slezská univerzita v Opavě", "length": 1 },
                    { "text": "", "length": 1 },
                    { "text": "Bakalářské studium", "length": 4 },
                    { "text": "Magisterské studium", "length": 4 },
                    { "text": "Navazující magisterské studium", "length": 4 },
                    { "text": "Doktorské studium", "length": 4 },
                ],
                [
                    { "text": "", "length": 1 },
                    { "text": "", "length": 1 },
                    { "text": "Počet uchazečů (fyzické osoby)", "length": 1 },
                    { "text": "Počet přihlášek", "length": 1 },
                    { "text": "Počet přijetí", "length": 1 },
                    { "text": "Počet zápisů ke studiu", "length": 1 },
                    { "text": "Počet uchazečů (fyzické osoby)", "length": 1 },
                    { "text": "Počet přihlášek", "length": 1 },
                    { "text": "Počet přijetí", "length": 1 },
                    { "text": "Počet zápisů ke studiu", "length": 1 },
                    { "text": "Počet uchazečů (fyzické osoby)", "length": 1 },
                    { "text": "Počet přihlášek", "length": 1 },
                    { "text": "Počet přijetí", "length": 1 },
                    { "text": "Počet zápisů ke studiu", "length": 1 },
                    { "text": "Počet uchazečů (fyzické osoby)", "length": 1 },
                    { "text": "Počet přihlášek", "length": 1 },
                    { "text": "Počet přijetí", "length": 1 },
                    { "text": "Počet zápisů ke studiu", "length": 1 },
                ]
            ]
    },
    6.1: {
        n_columns: 14,
        row_heights: [38.25, 90],
        table_headers: [
            [
                { "text": "Vysoká škola (název)", "length": 1 },
                { "text": "Akademičtí pracovníci", "length": 8 },
                { "text": "Vědečtí a odborní pracovníci**", "length": 3 },
                { "text": "Ostatní zaměstnanci*****", "length": 1 },
                { "text": "CELKEM zaměstnanci", "length": 1 }
            ],
            [
                { "text": "", "length": 1 },
                { "text": "CELKEM akademičtí pracovníci", "length": 1 },
                { "text": "Profesoři", "length": 1 },
                { "text": "Docenti", "length": 1 },
                { "text": "Odborní asistenti", "length": 1 },
                { "text": "Asistenti", "length": 1 },
                { "text": "Lektoři", "length": 1 },
                { "text": "Vědečtí, výzkumní a vývojoví pracovníci podílející se na pedagog. činnosti ", "length": 1 },
                { "text": "Mimořádní profesoři", "length": 1 },
                { "text": "Postdokto-randi (\"postdok\") ***", "length": 1 },
                { "text": "Vědečtí pracovníci nespadající do ostatních kategorií", "length": 1 },
                { "text": "Ostatní vědečtí, výzkumní a vývojoví pracovníci ****", "length": 1 },
                { "text": "Ostatní zaměstnanci*****", "length": 1 },
                { "text": "CELKEM zaměstnanci", "length": 1 }
            ]
        ]
    },
    6.2: {
        n_columns: 25,
        row_heights: [33.75, 58.5],
        table_headers: [
            [
                { "text": "", "length": 1 },
                { "text": "Akademičtí pracovníci", "length": "14" },
                { "text": "Vědečtí a odborní pracovníci", "length": "6" },
                { "text": "Ostatní zaměstnanci*****", "length": "2" },
                { "text": "CELKEM", "length": "1" },
                { "text": "z toho ženy", "length": "1" }
            ],
            [
                { "text": "", "length": 1 },
                { "text": "Profesoři", "length": 2 },
                { "text": "Docenti", "length": 2 },
                { "text": "Odborní asistenti", "length": 2 },
                { "text": "Asistenti", "length": 2 },
                { "text": "Lektoři", "length": 2 },
                { "text": "Vědečtí, výzkumní a vývojoví pracovníci podílející se na pedagog. činnosti", "length": 2 },
                { "text": "Mimořádní profesoři", "length": 2 },
                { "text": "Postdoktorandi (\"postdok\")***", "length": 2 },
                { "text": "Vědečtí pracovníci nespadající do ostatních kategorií", "length": 2 },
                { "text": "Ostatní vědečtí, výzkumní a vývojoví pracovníci****", "length": 2 },
                { "text": "", "length": 2 },
                { "text": "", "length": 2 },
            ],
            [
                { "text": "", "length": 1 },
                { "text": "CELKEM", "length": 1 },
                { "text": "ženy", "length": 1 },
                { "text": "CELKEM", "length": 1 },
                { "text": "ženy", "length": 1 },
                { "text": "CELKEM", "length": 1 },
                { "text": "ženy", "length": 1 },
                { "text": "CELKEM", "length": 1 },
                { "text": "ženy", "length": 1 },
                { "text": "CELKEM", "length": 1 },
                { "text": "ženy", "length": 1 },
                { "text": "CELKEM", "length": 1 },
                { "text": "ženy", "length": 1 },
                { "text": "CELKEM", "length": 1 },
                { "text": "ženy", "length": 1 },
                { "text": "CELKEM", "length": 1 },
                { "text": "ženy", "length": 1 },
                { "text": "CELKEM", "length": 1 },
                { "text": "ženy", "length": 1 },
                { "text": "CELKEM", "length": 1 },
                { "text": "ženy", "length": 1 },
                { "text": "CELKEM", "length": 1 },
                { "text": "ženy", "length": 1 },
                { "text": "", "length": 1 },
                { "text": "", "length": 1 },
            ]
        ]
    },
    6.3: {
        n_columns: 13,
        row_heights: [30, 15],
        table_headers: [
            [
                { "text": "", "length": 1 },
                { "text": "Akademičtí pracovníci", "length": 8 },
                { "text": "Vědečtí pracovníci*", "length": 2 },
                { "text": "CELKEM", "length": 1 },
                { "text": "z toho ženy", "length": 1 }
            ],
            [
                { "text": "", "length": 1 },
                { "text": "prof.", "length": 2 },
                { "text": "doc.", "length": 2 },
                { "text": "DrSc., CSc., Dr., Ph.D., Th.D.", "length": 2 },
                { "text": "ostatní", "length": 2 },
                { "text": "CELKEM", "length": 1 },
                { "text": "ženy", "length": 1 },
                { "text": "", "length": 1 },
                { "text": "", "length": 1 }
            ],
            [
                { "text": "Rozsahy úvazků", "length": 1 },
                { "text": "CELKEM", "length": 1 },
                { "text": "ženy", "length": 1 },
                { "text": "CELKEM", "length": 1 },
                { "text": "ženy", "length": 1 },
                { "text": "CELKEM", "length": 1 },
                { "text": "ženy", "length": 1 },
                { "text": "CELKEM", "length": 1 },
                { "text": "ženy", "length": 1 },
                { "text": "", "length": 1 },
                { "text": "", "length": 1 },
                { "text": "", "length": 1 },
                { "text": "", "length": 1 }
            ]
        ]
    },
    6.5: {
        n_columns: 11,
        row_heights: [15, 77.25],
        table_headers: [
            [
                { "text": "", "length": 1 },
                { "text": "Akademičtí pracovníci", "length": 6 },
                { "text": "Vědečtí a odborní pracovníci**", "length": 3 },
                { "text": "Ostatní zaměstnanci *****", "length": 1 }
            ],
            [
                { "text": "", "length": 1 },
                { "text": "Profesoři", "length": 1 },
                { "text": "Docenti", "length": 1 },
                { "text": "Odborní asistenti", "length": 1 },
                { "text": "Asistenti", "length": 1 },
                { "text": "Lektoři", "length": 1 },
                { "text": "Vědečtí, výzkumní a vývojoví prac. podílející se na ped. činnosti", "length": 1 },
                { "text": "Postdokto-randi (\"postdok\") ***", "length": 1 },
                { "text": "Vědečtí pracovníci nespadající do ostatních kategorií", "length": 1 },
                { "text": "Ostatní vědečtí, výzkumní a vývojoví pracovníci****", "length": 1 },
                { "text": "", "length": 1 },
            ]
        ]
    },
    7.1: {
        n_columns: 5,
        row_heights: [38.25, 41.25],
        table_headers:
            [
                [
                    { "text": "Vysoká škola (název)", "length": 1 },
                    { "text": "H2020/ 7. rámcový program EK", "length": 2 },
                    { "text": "", "length": 1 },
                    { "text": "", "length": 1 }
                ],
                [
                    { "text": "", "length": 1 },
                    { "text": "CELKEM", "length": 1 },
                    { "text": "Z toho Marie-Curie Actions", "length": 1 },
                    { "text": "Ostatní", "length": 1 },
                    { "text": "CELKEM", "length": 1 },
                ]
            ]
    },
    7.2: {
        n_columns: 9,
        row_heights: [38.25, 38.25],
        table_headers:
            [
                [
                    { "text": "Vysoká škola (název)", "length": 1 },
                    { "text": "Počet vyslaných studentů*", "length": 2 },
                    { "text": "", "length": 1 },
                    { "text": "", "length": 1 },
                    { "text": "", "length": 1 },
                    { "text": "", "length": 1 },
                    { "text": "", "length": 1 },
                    { "text": "", "length": 1 }
                ],
                [
                    { "text": "Země", "length": 1 },
                    { "text": "Celkem", "length": 1 },
                    { "text": "Z toho absolventské stáže******", "length": 1 },
                    { "text": "Počet přijatých studentů**", "length": 1 },
                    { "text": "Počet vyslaných akademických pracovníků***", "length": 1 },
                    { "text": "Počet přijatých akademických pracovníků****", "length": 1 },
                    { "text": "Počet vyslaných ostatních pracovníků***", "length": 1 },
                    { "text": "Počet přijatých ostatních pracovníků****", "length": 1 },
                    { "text": "CELKEM za zemi", "length": 1 }
                ]
            ]
    },
    7.3: {
        n_columns: 11,
        row_heights: [15, 15],
        table_headers:
            [
                [
                    { "text": "", "length": 1 },
                    { "text": "Bakalářské studium", "length": 2 },
                    { "text": "Magisterské studium", "length": 2 },
                    { "text": "Navazující magisterské studium", "length": 2 },
                    { "text": "Doktorské studium", "length": 2 },
                    { "text": "CELKEM**", "length": 2 }
                ],
                [
                    { "text": "Vysoká škola (název)", "length": 1 },
                    { "text": "podíl", "length": 1 },
                    { "text": "počet", "length": 1 },
                    { "text": "podíl", "length": 1 },
                    { "text": "počet", "length": 1 },
                    { "text": "podíl", "length": 1 },
                    { "text": "počet", "length": 1 },
                    { "text": "podíl", "length": 1 },
                    { "text": "počet", "length": 1 },
                    { "text": "podíl", "length": 1 },
                    { "text": "počet", "length": 1 }
                ]
            ]
    }
}


// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../images/titul_background.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nav-img-background {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    display: inline-block;\n    position: absolute;\n    background-size: contain;\n    height: 250px;\n    width: 100%;\n    margin-top: 58px;\n    z-index: 50;\n}\n\n.nav-img-title {\n    position: absolute;\n    left: 50%;\n    transform: translate(-50%, 0);\n}\n\n.nav-img-title-span {\n    /*width: 100%;*/\n    color: whitesmoke;\n    font-size: 15px;\n    width: 1200px;\n    position: absolute;\n    left: calc(50% - 600px);\n    text-align: center;\n    /*transform: translate(-50%, 0);*/\n    bottom: 20px;\n}\n\n@media screen and (max-width: 800px) {\n    .nav-img-background {\n        display: none;\n    }\n}\n\n", "",{"version":3,"sources":["webpack://src/css/navImage.css"],"names":[],"mappings":"AAAA;IACI,yDAAqD;IACrD,qBAAqB;IACrB,kBAAkB;IAClB,wBAAwB;IACxB,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,6BAA6B;AACjC;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,kBAAkB;IAClB,iCAAiC;IACjC,YAAY;AAChB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".nav-img-background {\n    background-image: url(../images/titul_background.jpg);\n    display: inline-block;\n    position: absolute;\n    background-size: contain;\n    height: 250px;\n    width: 100%;\n    margin-top: 58px;\n    z-index: 50;\n}\n\n.nav-img-title {\n    position: absolute;\n    left: 50%;\n    transform: translate(-50%, 0);\n}\n\n.nav-img-title-span {\n    /*width: 100%;*/\n    color: whitesmoke;\n    font-size: 15px;\n    width: 1200px;\n    position: absolute;\n    left: calc(50% - 600px);\n    text-align: center;\n    /*transform: translate(-50%, 0);*/\n    bottom: 20px;\n}\n\n@media screen and (max-width: 800px) {\n    .nav-img-background {\n        display: none;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

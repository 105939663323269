//Utilita pro exportovani vystupu do excelu a pdf v pozadovanem tvaru vyrocni zpravy.

const header_background_color = [204, 51, 0];
const header_font_color = [238, 255, 255];

function create_cell_json(value, width = 1, border= true, align = false, background=null, font_c=null) {              //background a font_c ve tvaru [r, g, b] = [int, int, int]
    //Vytvori json object ktery bude obsahovat informace o bunce tabulky (barva, sirka, hodnota)
    return { value: value, background: background, border: border, cell_width: width, color: font_c, align:align };
}

function create_header(header_text, header_width, align = false, background_color=false, font_color=false) {
    //vytvori radek tabulky header a nastavi atributy
    var bg_color = background_color ? header_background_color : null;
    var ft_color = font_color ? header_font_color : null;

    var header = []
    for (var i = 0; i < header_text.length; i++)
        header.push(create_cell_json(header_text[i], header_width[i], true, align, bg_color, ft_color));
    return header;
}

export function fragment_to_percentage(value) {
    //Prevedu absolutni hodnotu ve zlomku (1/3) na procenta
    if (value == "0")
        return value

    var res = value.split("/");

    if (res[1] == "0") {
        return res[0]
    }
    else {
        var percentage = parseInt(res[0]) * 100 / parseInt(res[1]);
        return percentage.toFixed(2);                                      //Zaokrouhlim na 2 desetina cisla                                                      
    }
}

async function create_single_table(tb_data, data, table_headers, row_heights) {
    //Funkce pro vytvoreni jednoduche tabulky
    //pridam zahlavi tabulky
    for (var i = 0; i < table_headers.length; i++) {
        var header_row = []
        var header_length = []
        for (var j = 0; j < table_headers[i].length; j++) {
            header_row.push(table_headers[i][j]["text"]);
            header_length.push(parseInt(table_headers[i][j]["length"]));
        }
        tb_data.data.push(create_header(header_row, header_length, true, true, true));
        tb_data.row_heights[tb_data.data.length] = row_heights[i];           //Pridam vysku radku zahlavi
    }
    //Pridam data do tabulky
    for (var i = 0; i < data.length; i++) {
        var row = [];
        for (var j = 0; j < data[i].length; j++) {
            row.push(create_cell_json(data[i][j]));
        }
        tb_data.data.push(row);
    }
    return tb_data;
}

async function create_sheet(year, data, table_title, headers, single_table = true, description = true) {
    //Vytvori excell sheet a prida zahlavi s tabulkama
    try {
        var date = new Date().toLocaleString("cs-CZ");
        var n_columns = single_table ? headers["n_columns"] : headers[Object.keys(headers)[0]]["n_columns"];
        var tb_data = {};
        tb_data.data = [];
        tb_data.row_heights = {};
        if (description) {              //U celkove exportovane VZ neni titulek
            tb_data.data.push([create_cell_json("Podklad pro VZ SU " + year, n_columns, false, true)]);
        }
        tb_data.data.push(create_header([table_title], [n_columns], true, true, true));

        if (single_table) {             //Ukazatel obsahuje jednu tabulku
            var table_headers = headers["table_headers"];
            var row_heights = headers["row_heights"];
            tb_data = await create_single_table(tb_data, data, table_headers, row_heights);
        }
        else {                          //Data obsahuje vice tabulek
            for (var table in data) {
                var table_header = headers[table];
                var table_headers = table_header["table_headers"];
                var row_heights = table_header["row_heights"];
                tb_data = await create_single_table(tb_data, data[table], table_headers, row_heights);
                tb_data.data.push([create_cell_json("", 1, false)]); //Pridam prazdny radek pro oddeleni tabulek
            }
        }

        tb_data.data.push([create_cell_json("Vytvořeno dne: " + date, n_columns, false, false)]); //Pridani datumu
       
        return tb_data;
    }
    catch (err) {
        alert("Data nejsou k dispozici!");
    }
    
}


function excel_export(tb_data, file_name, path) {
    //Vrati excell soubor v novem okne
    fetch(path, {
        method: 'POST',
        dataType: "JSON",
        body: JSON.stringify(tb_data),
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token'), // Add the authentication header
            "Accept": "application/json"
        },
    }).then(resp => resp.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = file_name;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
}

export const return_file = async (year, child_data, data, headers, path, description = true) => {
    //Vytvori exportovany soubor pro jednotlive ukazatele
    var table_number = child_data["number"] === undefined ? "" : child_data["number"];
    var title = child_data['title'];             
    var columns_length = child_data['columns_length']; 
    var single = child_data["single_table"] === undefined ? true : false;
    var tb_data = await create_sheet(year.getFullYear(), data, title, headers, single, description);

    tb_data["columns_width"] = columns_length;
    excel_export(tb_data, 'VZ-SU-' + year.getFullYear() + '_Tab' + table_number + '.xlsx', path);
}

async function fetch_data(location, year) {
    //Fetch dat z controlleru
    return await fetch(location, {
        method: 'POST',
        dataType: "JSON",
        body: JSON.stringify({ "year": year }),
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token'), // Add the authentication header
            "Accept": "application/json"
        },
    }).then(response => response.json())
        .then(data => {
            return data;
        });;
}

function create_table_headers(table_headers, data) {
    //Vytvori zahlavi pro jednotlive tabulky
    var headers = {};
    for (var table in data) {
        var deepCopyObj = JSON.parse(JSON.stringify(table_headers));
        deepCopyObj["table_headers"][0][0].text = table
        headers[table] = deepCopyObj;
    }
    return headers;
}

export const return_anual_report = async (menu, header, year) => {
    //Vytvori vyrocni zpravu a vrati vygenerovany excel soubor
    try {
        var tb_data = {};
        for (var menu_item in menu) {
            for (var item in menu[menu_item]) {
                if (!menu[menu_item][item].hasOwnProperty("number"))          //Ukazatel neni soucasti vyrocni zpravy
                    continue;

                const table_title = menu[menu_item][item].title;
                const data = await fetch_data(menu[menu_item][item].data_location, year);
                var table_headers = header[menu[menu_item][item].number];
                const width = menu[menu_item][item].columns_length;

                var single_table = true;                        //Pokud ukazatel obsahuje vice tabulek musim vytvorit header pro kazdou
                if (menu[menu_item][item]["single_table"] !== undefined && menu[menu_item][item]["single_table"] == false) {
                    table_headers = create_table_headers(table_headers, data);
                    single_table = false;
                }
                var table = await create_sheet(year, data, table_title, table_headers, single_table, false);
                table["columns_width"] = width;
                tb_data[menu[menu_item][item].number] = table;
            }
        }
        excel_export(tb_data, 'VZ-SU-' + year + '.xlsx', 'api/ExcellExport/GenerateAnualReport');
    }
    catch (err) {}
}
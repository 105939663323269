import React, { Component } from 'react';
import '../css/home.css';
import '../css/aboutApp.css';
import eu_logo from '../images/eu_logo.jpg'


export default class Home extends Component { 
    constructor(props) {
        super(props);
        this.renderNavList = this.renderNavList.bind(this);
        document.body.classList.add('home-back');
    }

    renderNavList(cat) {
        this.props.callbackFromParent(cat);
    }

    render() {
        return (
            <div className="about-wrapper">
                <div className="col-md-6 col-md-offset-3 about-app-main-div">
                    <h3>O aplikaci</h3>
                    <div>
                        {"Tato webov\u00e1 aplikace byla vytvo\u0159ena pracovn\u00edky Centra informa\u010Dn\u00edch technologi\u00ed Slezsk\u00E9 univerzity v Opav\u011B "}
                        {"za podpory projektu Evropsk\u00FDch struktur\u00e1ln\u00edch a investi\u010Dn\u00edch fond\u016F realizovan\u00E9ho v r\u00e1mci "}
                        {"opera\u010Dn\u00edho programu V\u00FDzkum, v\u00FDvoj a vzd\u011Bl\u00e1v\u00e1n\u00ed pod ozna\u010Den\u00edm CZ.02.2.69/0.0/0.0/16_0.15/0002400 "}
                        <a href="https://www.slu.cz/slu/cz/layout/635">{" Rozvoj vzd\u011Bl\u00e1v\u00e1n\u00ed na Slezsk\u00E9 univerzit\u011B v Opav\u011B. "}</a>
                        <br />
                        <br />
                        {"Pro hl\u00e1\u0161en\u00ed probl\u00E9m\u016F, oprav a n\u00e1m\u011Bt\u016F k t\u00E9to aplikaci pros\u00edm vyu\u017Eijte adresy podpora@slu.cz."}
                        <br />
                        <br />
                        <img src={eu_logo} className="nav-img-title eu_logo_img" />
                    </div>
                </div>
            </div>
        );
    }
}


import React, { Component } from 'react';
import '../css/navImage.css';
import title from '../images/titul_vetsi_vsude.png'

export class NavImage extends Component {

    render() {
        return (
            <div className="col-md-12 nav-img-background">
                <img src={title} className="nav-img-title" />
                <span className="nav-img-title-span">{this.props.title}</span>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { NavMenu } from './NavMenu';
import { RouteMenu } from './RouteMenu'
import { NavImage } from './NavImage'
import '../css/layout.css';
import { Footer } from './Footer'

export class Layout extends Component {
    displayName = Layout.name

    render() {
        return (
            <Grid fluid>
                <Row>
                    <NavMenu />
                    <NavImage title={this.props.title} />
                    <RouteMenu />
                    <Col>
                        <div>
                            {this.props.component}
                        </div>
                        <Footer />
                    </Col>
                </Row>
            </Grid>
        );
    }
}

import React, { Component } from 'react';
import '../../css/akreditovaneProgramy.css';
import '../../css/absolventiCizinci.css';
import '../../css/poctyStudii.css';
import { ManazerskaData } from './ManazerskaData';
import { ManazerskaDataMenu } from './ManazerskaDataMenu';


export class ProgramyAStudia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [] 
        };      
    }

    fetchData(year) {
        fetch(this.props.child_data["data_location"], {
            method: 'POST',
            dataType: "json",
            body: JSON.stringify({ "year": year }),
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'), // Add the authentication header
                "Accept": "application/json"
            },
        }).then(response => response.json())
            .then(data => {
                this.setState({"data": data });
            });;
    }
       
    render() {
        return (
            <ManazerskaData title="Počty akreditovaných studijních programů">
                <div>
                    <ManazerskaDataMenu fetchData={this.fetchData.bind(this)} headers={this.props.header} data={this.state.data} child_data={this.props.child_data} />
                    <div className="ac-table-div"> 
                        <table className='ap-table-auto-position col-sm-12'>
                            <div className="ap-head-div">
                                <thead className="ap-table-header-scrollbar">
                                    <tr>
                                        <th rowspan="2" colspan="5" scope="colgroup">Vysoká škola (název)</th>
                                        <th rowspan="1" colspan="2" scope="colgroup">Bakalářské</th>
                                        <th rowspan="1" colspan="2" scope="colgroup">Magisterské</th>
                                        <th rowspan="1" colspan="2" scope="colgroup">Navazující</th>
                                        <th rowspan="1" colspan="2" scope="colgroup">Doktorské</th>
                                        <th rowspan="2" colspan="1" scope="colgroup" className="ap-table-head-margin-last">Celkem</th>
                                    </tr>
                                    <tr>
                                        <th colspan="1">prezenční</th>
                                        <th colspan="1">kombinované</th>
                                        <th colspan="1">prezenční</th>
                                        <th colspan="1">kombinované</th>
                                        <th colspan="1">prezenční</th>
                                        <th colspan="1">kombinované</th>
                                        <th colspan="1">prezenční</th>
                                        <th colspan="1">kombinované</th>
                                    </tr>
                                </thead>
                                <div className="ap-table-pad"></div>
                            </div>
                            <tbody className="ac-tbody" id="ap-uk-tab">
                            {
                                this.state.data.map((row) => (
                                    <tr className="pr_tr ed-uk-tr">
                                        <td colspan="4" className="pr_td">{row[0]}</td>
                                        <td colspan="1" className="pr_td ps-num-tab-col">{row[1]}</td>
                                        <td colspan="1" className="pr_td ps-num-tab-col">{row[2]}</td>
                                        <td colspan="1" className="pr_td ps-num-tab-col">{row[3]}</td>
                                        <td colspan="1" className="pr_td ps-num-tab-col">{row[4]}</td>
                                        <td colspan="1" className="pr_td ps-num-tab-col">{row[5]}</td>
                                        <td colspan="1" className="pr_td ps-num-tab-col">{row[6]}</td>
                                        <td colspan="1" className="pr_td ps-num-tab-col">{row[7]}</td>
                                        <td colspan="1" className="pr_td ps-num-tab-col">{row[8]}</td>
                                        <td colspan="1" className="pr_td ps-num-tab-col">{row[9]}</td>
                                        <td colspan="1" className="pr_td ps-num-tab-col">{row[10]}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </ManazerskaData>        
         );
    }
}

import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Message, Icon } from 'semantic-ui-react'
import ReactHtmlParser from "react-html-parser";
import '../../css/editaceUkazatelu.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

export class EditaceUkazatelu extends Component  {
    //Komponenta zobrazi tabulku s evidovanymi ukazateli po rozkliknuti zobrazi formular jako child componentu
    constructor(props) {
        super(props);
        this.state = {
             questionaire: false, id: null, data: []
        };
    }
    componentDidMount() {
        this.get_table_data();
    }

    get_table_data() {                                  //Vrati data o ulozenych formularich
        fetch('api/Evidence/GetSummary', {
            method: 'POST',
            dataType: "json",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Accept": "application/json"
            },
        }).then(response => response.json())
            .then(data => {
                this.setState({ data: data })
            })
    }

    notice = (text) => {                                        //Vytvori okno s upozornenim
        return (
            <div className="ed-uk-message col-md-4 col-md-offset-4 col-sm-12">
                <Message>
                    <Message.Header>{ReactHtmlParser(text)}</Message.Header>
                    <Message.List>
                        <button onClick={() => { this.setState({ component: null }) }} class="ui button export-message-btn">Zavřít<Icon disabled name='times circle' className='export-message-btn-icon' /></button>
                    </Message.List>
                </Message>
            </div>
        );
    }

    deleteForm(id) {                                    //POST na controller pro smazani formulare
         fetch('api/Evidence/DeleteQuestionnaire', {
                method: 'POST',
                dataType: "json",
                body: JSON.stringify({
                    "id": id
                }),
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('token'),
                    "Accept": "application/json"
                },
         }).then(response => response.json())
                .then(data => {
                    if (data) {
                        this.setState({
                            component: this.notice("Smazáno <i class=\"ed-uk-gl-save glyphicon glyphicon-ok\">")
                        }, () => {
                            var top = window.screen.width >= 768 ? 250 : 0;
                            window.scrollTo({ top: top })
                            this.get_table_data();
                        });
                    }
                });
    }

    submit = (id) => {                                        //Okno pro potvrzeni smazani formulare
        confirmAlert({
            title: 'Smazat',
            message: 'Opravdu chcete smazat formulář?',
            buttons: [
                {
                    label: 'Ano',
                    onClick: () => this.deleteForm(id)
                },
                {
                    label: 'Ne',
                }
            ]
        });
    };

    close_component = (data) => {                       //Callback, ktery odstrani child component
        this.setState({
            id: null,
            questionaire: false
        }, () => {
            if (data) {
                this.setState({
                    component: this.notice("Úspěšně uloženo <i class=\"ed-uk-gl-save glyphicon glyphicon-ok\">")
                }, () => {
                    var top = window.screen.width >= 768 ? 250 : 0;
                    window.scrollTo({ top: top })
                    this.get_table_data();
                });
            }
        });
    }
      
    update_form = (id) => {
        if (id !== null) {
            this.setState({
                id: id,
                questionaire: true
            });
        }
    }

    new_form = () => {
        this.setState({
            questionaire: true
        });
    }

    render() {
        let child;
        if (this.state.questionaire) {
            var top = window.screen.width >= 768 ? 250 : 0;
            window.scrollTo({ top: top })
            child = React.cloneElement(
                React.Children.only(this.props.children),
                {
                    questionaireID: this.state.id,
                    callbackFromParent: this.close_component
                }
            );
        }
        return (
            <div className="ed-uk-comp-margin-top ed-wrapper">
                <div>
                    <div className="ed-uk-form col-md-12">
                        {child}
                    </div>
                    {this.state.component}
                    <div className="ac-table-div">
                        <table className="ap-table-auto-position col-sm-12" id="ed-uk-tab">
                                <thead className="pr-thead">
                                        <tr className="text-left">
                                            <th colspan="1" className="pr_th ed-uk-index-th"></th>
                                            <th colspan="4" className="pr_th ac-th"><div className="ap-th ac-table-paragraph">DEFINICE UKAZATELE</div></th>
                                            <th colspan="3" className="pr_th ac-th"><div className="ap-th ac-table-paragraph">ČÍSLO UKAZATELE</div></th>
                                            <th colspan="3" className="pr_th ac-th"><div className="ap-th ac-table-paragraph">OBLAST UKAZATELE</div></th>
                                            <th colspan="3" className="pr_th"></th>
                                        </tr>
                                </thead>
                            <tbody id="ed-uk-body" className="pr-tbody">
                                {
                                    this.state.data.map((row, index) => (
                                        <tr className="pr_tr ed-uk-tr">
                                            <td colspan="1" className="pr_td">{index + 1}</td>
                                            <td colspan="4" className="pr_td">{row[0]}</td>
                                            <td colspan="3" className="pr_td">{row[1]}</td>
                                            <td colspan="3" className="pr_td">{row[2]}</td>
                                            <td colspan="3" className="pr_td">
                                                <button onClick={() => this.update_form(row[4])} className="ed-uk-chang-btn">Upravit</button>
                                                <button onClick={() => this.submit(row[4])} className="ed-uk-rm-btn">Smazat</button>
                                            </td>
                                        </tr>
                                    ))
                                }
                                <tr className="pr_tr ed-uk-tr-last">
                                    <td colspan="1" className="pr_td"></td>
                                    <td colspan="4" className="pr_td"></td>
                                    <td colspan="3" className="pr_td"></td>
                                    <td colspan="3" className="pr_td"></td>
                                    <td colspan="3" className="pr_td"><button onClick={this.new_form} className="ed-uk-rm-btn ed-uk-sh-left"><i className="glyphicon glyphicon-plus ud-uk-glyph"></i> Nový</button></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>              
             </div>
        );
    }
}